import { gameTitleAtom, menuIsOpenAtom } from "@/common/recoil";
import Ant from "@/components/Ant";
import Game from "@/components/games/Game";
import Toolbar from "@/components/Toolbar";
import Workbook from "@/components/Workbook";
import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

export default function StudiaGame() {
    const menuIsOpen = useRecoilValue(menuIsOpenAtom)
    const gameTitleSet = useSetRecoilState(gameTitleAtom)
    const operators = [2, 3, 4, 5, 6, 7, 8, 9];

    useEffect(()=> {
        //  TODO: Creare selector recoil con oggetto config.game
        gameTitleSet("Studia")
    }, [])
    
    return <Game>
        
        <Workbook operators={operators} />
        <Ant />
        <Toolbar />
    
    </Game>;
}