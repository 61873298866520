import { sleep } from "@/common/functions";
import {
  gameConfigAtom,
  gameTitleAtom,
  hintShowAtom,
  menuIsOpenAtom,
  nextAnswerAtom,
  playSoundGameOverAtom,
  resultShowAtom
} from "@/common/recoil";
import Calculator from "@/components/Calculator";
import Game from "@/components/games/Game";
import useGameFunctions from "@/components/games/ProvaGame/hooks/useGameFunctions";
import {
  antAnimationAtom,
  inputAtom,
  raceStateAtom
} from "@/components/games/ProvaGame/recoil";
import Race from "@/components/Race";
import Workbook from "@/components/Workbook";
import config from "@/config";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

export default function ProvaGame() {
  const [inputValue, setInputValue] = useRecoilState(inputAtom);
  const [nextAnswer, setNextAnswer] = useRecoilState(nextAnswerAtom);
  const menuIsOpen = useRecoilValue(menuIsOpenAtom);
  const raceState = useRecoilValue(raceStateAtom);
  const gameTitleSet = useSetRecoilState(gameTitleAtom);
  const setHintShow = useSetRecoilState(hintShowAtom);
  const setResultShow = useSetRecoilState(resultShowAtom);
  const setplaySoundGameOver = useSetRecoilState(playSoundGameOverAtom);

  const setGameConfig = useSetRecoilState(gameConfigAtom);
  const setAntAnimation = useSetRecoilState(antAnimationAtom);
  const setRaceState = useSetRecoilState(raceStateAtom);

  const { answerCheck, isExeEnded } = useGameFunctions();

  const operators = [2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    //  TODO: Creare selector recoil con oggetto config.game
    gameTitleSet("Prova");
    setHintShow(true);
    setResultShow(false);
    setplaySoundGameOver(false);

    setAntAnimation("idle");
    setRaceState("running");
  }, []);

  // ON NEXT ANSWER
  useEffect(() => {
    if (!nextAnswer) return;
    if (raceState !== "running") return;

    (async () => {
      if (nextAnswer === "C") {
        setInputValue((i) => i && i.slice(0, -1));
      } else {
        // setInputValue(i => i && i + String(nextAnswer) || String(nextAnswer))
        const { ms } = await answerCheck();
        const isEnded = await isExeEnded();
        console.log("isEnded", isEnded);
        isEnded &&
          (async () => {
            console.log("END");
            setRaceState("paused");
            // await sleep(config.games.prova.delayAfterWin);
            await sleep(ms);
            setRaceState("win");
          })();
      }

      setNextAnswer(null);
    })();
  }, [nextAnswer]);

  return (
    <Game>
      <Workbook operators={operators} hintShowOnHold={true} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Calculator inputActive={true} input={inputValue} />
      </div>
      <Race />
      {/* <Dev /> */}
      {/* <Toolbar /> */}
    </Game>
  );
}
