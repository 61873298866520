import { menuIsOpenAtom } from "@/common/recoil";
import { vh } from "@/common/styled-components";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import styled, { css } from "styled-components";

export default function MenuElement({ el }) {
  const { menuIcon, title, slug } = el;
  const navigate = useNavigate();

  const menuIsOpenSet = useSetRecoilState(menuIsOpenAtom);
  const [showLevels, showLevelsSet] = useState(false);

  const clickHandle = () => {
    if (el.disabled) {
      alert("Gioco disabilitato");
      return;
    }
    console.log(el);
    if (el.levels) {
      showLevelsSet(true);
      console.log(el.levels);
    } else {
      navigate(`/${slug}/0`);
      menuIsOpenSet(false);
    }
  };

  return (
    <Elem onClick={clickHandle} className={`${showLevels && "has-levels"}`}>
      {!showLevels ? (
        <>
          <img src={menuIcon} alt="" />
          {title}
        </>
      ) : (
        <LevelSelector data-n={Object.keys(el.levels).length}>
          {Object.keys(el.levels).map((level, i) => (
            <div key={i}>
              <Link
                key={i}
                onClick={() => menuIsOpenSet(false)}
                to={`/${slug}/${level}`}
              >
                <span>{level}</span>
              </Link>
            </div>
          ))}
        </LevelSelector>
      )}
    </Elem>
  );
}

const LevelSelector = styled.div`
  display: flex;
  /* height: 100%; */
  justify-content: space-around;
  align-items: center;
  /* gap: 2vw; */
  flex-wrap: wrap;

  a {
    display: flex;
    width: ${vh(7)};
    height: ${vh(7)};
    margin-top: ${vh(2)};
    margin-bottom: ${vh(2)};
    border-radius: 50%;
    border: 2px solid var(--c-blue-d);
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--c-blue-d);

    > span {
      transform: translateY(0.15em);
    }
  }

  > div {
    /* border: 1px solid; */
    display: flex;
    justify-content: center;
    
    ${p => p["data-n"] === 4 && css`
        min-width: 49%;
    `}
    
    ${p => p["data-n"] === 6 && css`
        min-width: 32%;
    `}
  }
`;

export const Elem = styled.div`
  --p: ${vh(3)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--c-blue-d);
  text-decoration: unset;
  font-size: ${vh(4)};
  padding: var(--p);
  font-family: "Gill Sans";
  width: 25vw;

  &:not(.has-levels) {
    cursor: pointer;
  }

  &:nth-of-type(2n) {
    border-left: 2px solid var(--c-blue-d);
  }

  border-bottom: 2px solid var(--c-blue-d);

  &:hover {
    color: var(--c-blue-d);
  }

  img {
    height: ${vh(20)};
  }
`;
