import gameOver from "@/assets/audio/game_over.mp3";
import negative from "@/assets/audio/negativeFeedback.mp3";
import positive from "@/assets/audio/positiveFeedback.mp3";
import positiveLong from "@/assets/audio/positiveLongFeedback.mp3";
import {
  playSoundGameOverAtom,
  playSoundRightAtom,
  playSoundRightLongAtom,
  playSoundWrongAtom
} from "@/common/recoil";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import useSound from "use-sound";

export default function useSounds() {
  // const soundRight = () => {
  //   const [play] = useSound(positive);
  //   play();
  // };
  // const soundWrong = () => {
  //   const [play] = useSound(negative);
  //   play();
  // };
  // const soundGameOver = () => {
  //   const [play] = useSound(gameOver);
  //   play();
  // };

  const SoundsController = () => {
    const playSoundRight = useRecoilValue(playSoundRightAtom);
    const playSoundRightLong = useRecoilValue(playSoundRightLongAtom);
    const playSoundWrong = useRecoilValue(playSoundWrongAtom);
    const playSoundGameOver = useRecoilValue(playSoundGameOverAtom);

    const [playPositive] = useSound(positive);
    const [playPositiveLong] = useSound(positiveLong);
    const [playNegative] = useSound(negative);
    const [playGameOver] = useSound(gameOver);

    useEffect(() => {
      if (!playSoundRight) return;
      playPositive();
    }, [playSoundRight]);
    
    useEffect(() => {
      if (!playSoundRightLong) return;
      playPositiveLong();
    }, [playSoundRightLong]);

    useEffect(() => {
      if (!playSoundWrong) return;
      playNegative();
    }, [playSoundWrong]);

    useEffect(() => {
      console.log("playSoundGameOver", playSoundGameOver);
      if (!playSoundGameOver) return;
      playGameOver();
    }, [playSoundGameOver]);

    return null;
  };

  return { 
    // soundRight, soundWrong, soundGameOver, 
    SoundsController };
}
