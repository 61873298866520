// @ts-ignore
import { vh } from "@/common/styled-components";
import MenuContainer from "@/components/MenuContainer";
import usePrecache from "@/hooks/usePrecache";
import useSounds from "@/hooks/useSounds";
import Crediti from "@/pages/Crediti";
import Game from "@/pages/Game";
import Home from "@/pages/Home";
import Istruzioni from "@/pages/Istruzioni";
// import { Auth } from "@hgblu/erickson-mab.common.auth";
import { Auth } from "@/components/auth";
import Splash from "@/pages/Splash";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import "./index.css";

function App() {
  const { precacheImages } = usePrecache();
  const { SoundsController } = useSounds();

  // GET FAKE VIEWPORT HEIGHT
  useEffect(() => {
    const getWH = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", getWH);
    getWH();

    return () => window.removeEventListener("resize", getWH);
  }, []);

  useEffect(() => {
    precacheImages();
    // console.log("ONCE")
  }, []);

  return (
    <>
      <GlobalStyle />
      <SoundsController />
      <Auth dev={process.env.NODE_ENV === "development" || process.env.VERCEL}>
        {/* <Auth dev={true}> */}
        <Router
          basename={
            process.env.NODE_ENV === "development" || process.env.VERCEL
              ? "/"
              : "/mab/tabelline-digitali"
          }
        >
          <MenuContainer />

          <Routes>
            <Route index element={<Navigate to="/splash" />} />
            {/* @ts-ignore */}
            <Route path="/splash" element={<Splash />} />
            <Route path="/home" element={<Home />} />
            <Route path="/istruzioni" element={<Istruzioni />} />
            <Route path="/crediti" element={<Crediti />} />

            {/* @ts-ignore */}
            <Route path="/:game/:level" element={<Game />} />
          </Routes>
        </Router>
      </Auth>
    </>
  );
}

export default App;

const GlobalStyle = createGlobalStyle`
  :root {
    --c-main: #faf8e0;
    --c-main-d: #FAF8E0;
    --c-brown: #4f2618;
    --c-blue: #4c8593;
    --c-blue-l: #80AFBB;
    --c-blue-l10: #B7D2D8;
    --c-blue-d: #265C69;
    --c-grey: #B9C9CE;
    --c-grey-l: #d2e2e7;
    --border-radius: 16px;

    --font-gill: "Gill Sans";
    --font-museo: "Museo Analogico";

    --bs-font-sans-serif: var(--font-gill);

    --division-cell-x: ${vh(5.3)};
  }

  width: 100%;
  height: 100%;

  body {
    background: var(--c-blue);
    overflow: hidden;
  }

  * {
    -webkit-tap-highlight-color: transparent;

    -webkit-touch-callout: none !important; /* iOS Safari */
    -webkit-user-select: none !important; /* Safari */
    -khtml-user-select: none !important; /* Konqueror */
    -moz-user-select: none !important; /* Old version of Firefox */
    -ms-user-select: none !important; /* Internet Explorer or Edge */
    user-select: none !important; /* All modern browsers */
  }
`;
