import {
  currentOperatorAtom,
  hintShowAtom,
  resultShowAtom
} from "@/common/recoil";
import { vh } from "@/common/styled-components";
import { exeAtom as divisionExeAtom } from "@/components/games/DivisioniGame/recoil";
import { raceLeftPositionAtom } from "@/components/games/ProvaGame/recoil";
import Labels from "@/components/Workbook/Labels";
import PaperDivision from "@/components/Workbook/PaperDivision";
import config from "@/config";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled, { css } from "styled-components";

const Operation = ({ number, operator, operation, hintShowOnHold }) => {
  const resultShow = useRecoilValue(resultShowAtom);
  const hintShow = useRecoilValue(hintShowAtom);
  const [currentResultShow, setCurrentResultShow] = useState(false);
  /**
   * @type {import("react").MutableRefObject<HTMLDivElement>}
   */
  const ref = useRef();

  useEffect(() => {
    if (!hintShowOnHold) return;
    if (!ref?.current) return;

    const down = () => setCurrentResultShow(true);
    const up = () => setCurrentResultShow(false);

    ref.current.addEventListener("pointerup", up);
    ref.current.addEventListener("pointerdown", down);

    return () => {
      if (!ref?.current) return;
      ref.current.removeEventListener("pointerdown", down);
      ref.current.removeEventListener("pointerup", up);
    };
  }, []);

  const icon = config?.operators?.[operator]?.hints?.[number];

  let result, op;

  switch (true) {
    case operation === "mult":
      op = "x";
      result = number * operator;
      break;

    default:
      break;
  }

  return (
    <OperationElem ref={ref} data-hint={hintShowOnHold}>
      {hintShowOnHold && <div className="text-selection-hider"></div>}
      <div className="operation">
        <span>{operator}</span>
        <span>{op}</span>
        <span>{number}</span>
        <span>= </span>
      </div>
      <div className="result" data-result-show={currentResultShow}>
        {(resultShow || currentResultShow) && <span>{result}</span>}
      </div>
      {(icon && (
        <img
          className="hint"
          src={icon}
          style={{
            opacity: hintShow ? 1 : 0,
          }}
        />
      )) || <div className="hint"></div>}
    </OperationElem>
  );
};

export default function Workbook({
  operators,
  division = false,
  hintShowOnHold = false,
}) {
  const { numbers, operators: configOperators } = config;
  const [currentOperator, currentOperatorSet] =
    useRecoilState(currentOperatorAtom);
  const divisionExe = useRecoilValue(divisionExeAtom);
  const setRaceLeftPosition = useSetRecoilState(raceLeftPositionAtom);

  const [workbookColor, workbookColorSet] = useState("#000000");

  const wbRef = useRef();

  useEffect(() => {
    if (!currentOperator) return;
    workbookColorSet(
      configOperators[currentOperator]?.fontColor ||
        configOperators[currentOperator].color
    );
  }, [currentOperator]);

  const currentOperation = "mult";

  useEffect(() => {
    if (!division || !divisionExe) return;

    currentOperatorSet(divisionExe[1]);
  }, [division, divisionExe]);

  useEffect(() => {
    if (!wbRef?.current) return;
    const resize = () =>
      setRaceLeftPosition(wbRef.current.getBoundingClientRect().x);

    window.addEventListener("resize", resize);
    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [wbRef.current]);

  return (
    <Elem ref={wbRef} color={workbookColor} data-division={division}>
      <Labels
        division={division}
        operators={operators}
        onClickFn={(n) => currentOperatorSet(n)}
      />
      <div className="paper">
        {division && <PaperDivision currentOperator={currentOperator} />}
        {!division &&
          numbers.map((n, i) => (
            <Operation
              key={i}
              number={n}
              operator={currentOperator}
              operation={currentOperation}
              hintShowOnHold={hintShowOnHold}
            />
          ))}
      </div>
    </Elem>
  );
}

const OperationElem = styled.div`
  font-size: ${vh(4)};
  display: flex;
  /* width: 100%; */
  height: 100%;
  /* border: 1px solid; */
  align-items: center;
  margin-left: 3vw;
  position: relative;

  &:nth-child(n + 1):nth-child(-n + 5) {
    margin-left: 1vw;
  }

  ${(p) =>
    p["data-hint"] &&
    css`
      cursor: pointer;
    `}

  .operation {
    display: flex;
    justify-content: space-between;
    user-select: none !important;
    position: relative;

    > span {
      display: block;
      width: ${vh(4)};
      text-align: center;
    }
  }

  .text-selection-hider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 100;
  }

  .result {
    font-weight: bold;
    display: block;
    width: ${vh(5)};
    text-align: center;
    margin-right: ${vh(1)};
    height: 1.2em;
    font-size: 1.3em;
    letter-spacing: 0.1em;
    border: 1px solid transparent;
    font-weight: bold;
  }

  .hint {
    height: ${vh(12)};
    width: ${vh(12)};
    object-fit: contain;
  }

  &[data-hint="true"] {
    .result:not([data-result-show="true"]) {
      border: 1px solid;
    }
  }
`;

const Elem = styled.div`
  --padding: ${vh(2)};
  /* width: 70%; */
  height: ${vh(66)};
  position: relative;
  color: ${(props) => props.color};
  font-family: var(--font-museo);

  .paper {
    position: relative;
    z-index: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 4fr 4fr 6fr 4fr 4fr;
    grid-auto-flow: column;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: #fff;
    width: calc(100% - var(--padding) * 2);
    height: calc(100% - var(--padding) * 2);
    padding: var(--padding);
    /* padding-right: ${vh(4)}; */
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }

  ${(props) =>
    props["data-division"] &&
    css`
      width: fit-content;
      height: ${vh(78)};
      margin-right: ${vh(7)};

      .paper {
        display: flex;
        border-bottom-right-radius: 0;
      }
    `}
`;
