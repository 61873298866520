import { vh } from "@/common/styled-components";
import { exeAtom } from "@/components/games/ProvaGame/recoil";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

export default function Monitor({ input }) {
  const exe = useRecoilValue(exeAtom);

  return <Index>{exe?.[0]} × {exe?.[1]} = {input}</Index>;
}

const Index = styled.div`
  background-color: var(--c-main);
  width: 110%;
  margin-left: -5%;
  height: ${vh(6)};
  border-radius: ${vh(1)};
  margin-bottom: ${vh(1)};
  font-size: ${vh(4)};
  font-family: var(--font-museo);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${vh(2)};
  color: var(--c-blue);
  box-sizing: border-box;
`;
