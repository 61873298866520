import { calculatorRightPositionAtom, gameConfigAtom } from "@/common/recoil";
import { vh } from "@/common/styled-components";
import { nExeAtom } from "@/components/games/ProvaGame/recoil";
import config from "@/config";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

export default function ExeCounter() {
  const calculatorRightPosition = useRecoilValue(calculatorRightPositionAtom);
  const nExe = useRecoilValue(nExeAtom);
  const [total, setTotal] = useState(0);

  const { level } = useParams();

  useEffect(() => {
    if (!level) return;
    setTotal(config.games.prova.levels[level].exercises.length);
  }, [level]);

  return (
    <Index data-right={calculatorRightPosition}>
      <div className="counter">
        <span>
          {nExe + 1}
          <span>/</span>
          {total}
        </span>
      </div>
    </Index>
  );
}

const Index = styled.div`
  --right: ${(props) => props["data-right"]}px;
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: flex-end;

  > .counter {
    background-color: var(--c-blue-l);
    margin-right: var(--right);
    height: fit-content;
    font-size: ${vh(3)};
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--c-main);
    width: ${vh(15)};
    height: ${vh(7)};
    border-radius: ${vh(1)};
    font-family: var(--font-museo);
    font-weight: bold;

    > span {
      > span {
        margin: 0 ${vh(1)};
      }
    }
  }
`;
