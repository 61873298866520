import arch1 from "@/assets/img/arches/arch1.svg";
import arch2 from "@/assets/img/arches/arch2.svg";
import { nextAnswerAtom } from "@/common/recoil";
import { vh } from "@/common/styled-components";
import {
  exeAtom,
  isArchSelectedAtom
} from "@/components/games/DivisioniGame/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";

export default function ArchSelection() {
  const exe = useRecoilValue(exeAtom);
  const setIsArchSelected = useSetRecoilState(isArchSelectedAtom);
  const setNextAnswer = useSetRecoilState(nextAnswerAtom);

  const sel = (arch) => {
    if (arch !== exe[2]) return;
    setIsArchSelected(true);
    setNextAnswer(null);
  };

  return (
    <Elem>
      <Arch onClick={() => sel(1)}>
        <img src={arch1} />
      </Arch>
      <Arch onClick={() => sel(2)}>
        <img src={arch2} />
      </Arch>
    </Elem>
  );
}

const Elem = styled.div`
  position: absolute;
  top: ${vh(13)};
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1vh;
`;

const Arch = styled.button`
  border: 0px;
  height: ${vh(6)};
  margin-right: ${vh(2)};
  border-radius: var(--border-radius);
  background-color: var(--c-main);
  color: var(--c-blue);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 0 ${vh(2, false)};

  &:nth-child(1) {
    min-width: ${vh(6)};
  }

  &:nth-child(2) {
    min-width: ${vh(10)};
  }

  &:hover {
    background-color: var(--c-grey-l);
  }

  img {
    height: 12px;
  }
`;
