import { Answer } from "@/components/games/MoltiplicazioniGame/Grid2";
import { currentSlotNAtom } from "@/components/games/MoltiplicazioniGame/recoil";
import Slot, { SlotElem } from "@/components/games/MoltiplicazioniGame/Slot";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

export default function Sum({ n, sumAns }) {
    const currentSlotN = useRecoilValue(currentSlotNAtom);
    
  useEffect(() => {
    console.group("Sum");
    console.log("sumAns", sumAns);
    console.groupEnd();
  }, []);
  
  return (
    <Container>
      <Answer>
        <SlotElem />
        {sumAns && sumAns.map((slot, i) => {
            n++;
            const isCurrent = n === currentSlotN;
            return (
              <Slot
                className={`answer-slot ${isCurrent && "current"}`}
                answer={slot}
                n={n}
                key={i}
                />
            )
        })}
      </Answer>
    </Container>
  );
}

const Container = styled.div``;
