import { calculatorRightPositionAtom, nextAnswerAtom } from "@/common/recoil";
import { vh } from "@/common/styled-components";
import Monitor from "@/components/Calculator/Monitor";
import { useEffect, useRef } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";

export default function Calculator({
  input = null,
  inputActive = false,
  style = {},
}) {
  const nextAnswerSet = useSetRecoilState(nextAnswerAtom);
  const setCalculatorRightPostion = useSetRecoilState(
    calculatorRightPositionAtom
  );
  const calcRef = useRef();

  const trigger = (num) => nextAnswerSet(num);
  const cancel = () => {};

  const keyListener = (e) => {
    if (e.key > -1 && e.key < 10) {
      trigger(e.key);
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== "development" && !process.env.VERCEL) return;

    window.addEventListener("keydown", keyListener);

    return () => {
      window.removeEventListener("keydown", keyListener);
    };
  }, []);

  useEffect(() => {
    if (!calcRef?.current) return;
    const resize = () => {
      const rect = calcRef.current.getBoundingClientRect();
      setCalculatorRightPostion(window.innerWidth - rect.x - rect.width);
    };

    window.addEventListener("resize", resize);
    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [calcRef]);

  return (
    <>
      {inputActive && <Monitor input={input} />}
      <Elem style={style} ref={calcRef}>
        <Buttons>
          {[
            [1, 2, 3],
            [4, 5, 6],
            [7, 8, 9],
            ["0", "C"],
          ].map((row, i) => (
            <div key={i}>
              {row.map((num) => (
                <Button key={num} onClick={() => trigger(num)}>
                  {num}
                </Button>
              ))}
            </div>
          ))}
        </Buttons>
      </Elem>
    </>
  );
}

const br = vh(1);

const Elem = styled.div`
  --br: ${br};

  z-index: 5;
  right: var(--game-padding-x);
  bottom: var(--game-padding);
  background-color: var(--c-blue-l);
  padding: ${vh(1.5)};
  border-radius: var(--br);
  width: fit-content;

  * {
    font-family: var(--font-museo);
    font-weight: bold;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: center;
  }
`;

const Button = styled.button`
  border: 1px solid;
  width: ${vh(6)};
  height: ${vh(6)};
  border-radius: var(--br);
  margin: ${vh(0.2)};
  border: 0px;
  font-size: ${vh(3)};
  cursor: pointer;
  background-color: var(--c-main);
  color: var(--c-blue);
  padding: 0;

  &:hover {
    background-color: var(--c-main-d);
  }
`;
