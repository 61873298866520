// @ts-ignore
import { ReactComponent as DotsSvg } from "@/assets/img/ui/sheet-dots.svg";
import { nextAnswerAtom } from "@/common/recoil";
import { vh } from "@/common/styled-components";
import ArchesDown from "@/components/games/DivisioniGame/ArchesDown";
import ArchesFirstStep from "@/components/games/DivisioniGame/ArchesFirstStep";
import ArchSelection from "@/components/games/DivisioniGame/ArchSelection";
import Slot, { Cell } from "@/components/games/DivisioniGame/Slot";
import SlotRowOne from "@/components/games/DivisioniGame/SlotRowOne";
import config from "@/config";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import useGameFunctions from "./hooks/useGameFunctions";
import {
  exeAtom,
  isArchSelectedAtom,
  isVerifyingAtom,
  nExeAtom,
  rowOneAtom,
  stepAtom,
  stepsAtom,
  stepsValuesLenAtom
} from "./recoil";

export default function Sheet() {
  const { level, game } = useParams();
  const {
    objectGenerate,
    insertNextAnswer,
    isExeEnded,
    nextExercise,
    clearExe,
    setFamilyMember,
  } = useGameFunctions();

  // RECOIL
  const [exe, setExe] = useRecoilState(exeAtom);
  const [nExe, setNExe] = useRecoilState(nExeAtom);
  const [rowOne, setRowOne] = useRecoilState(rowOneAtom);
  const [steps, setSteps] = useRecoilState(stepsAtom);
  const [step, setStep] = useRecoilState(stepAtom);
  const [verifying, setVerifying] = useRecoilState(isVerifyingAtom);
  const [isArchSelected, setIsArchSelected] =
    useRecoilState(isArchSelectedAtom);
  const nextAnswer = useRecoilValue(nextAnswerAtom);
  const setStepsValuesLen = useSetRecoilState(stepsValuesLenAtom);

  useEffect(() => {
    console.log("SHEET INIT");

    return () => {
      console.log("SHEET DESTROY");
    };
  }, []);

  // ON LEVEL CHANGE
  useEffect(() => {
    if (!level) return;
    setIsArchSelected(false);
    setNExe(0);
  }, [level]);

  useEffect(() => {
    setExe(config.games.divisioni.levels[level].exercises[nExe]);
  }, [level, nExe]);

  // ON EXERCISE CHANGE
  useEffect(() => {
    if (!exe) return;
    console.group("NEW EXE", exe);
    const obj = objectGenerate(exe);

    setStep(0);

    setSteps(obj.steps.values);

    obj.steps.values.forEach((s, i) => {
      setFamilyMember(i, s);
    });

    // @ts-ignore
    setStepsValuesLen(obj.steps.values.length);

    let ro = [
      ...String(exe[0]).split(""),
      ":",
      ...String(exe[1]).split(""),
      "=",
      ...[...Array(obj.gridResult.cols)].map((_, j) => {
        const elem = obj.steps.values.find(
          ({ type, col }) => type === "result" && col === j
        );

        return { ...elem, j };
      }),
    ];
    ro = Object.assign(new Array(10).fill(""), ro);
    setRowOne(ro);
    console.log("row one", ro);
    console.log("obj", obj);

    console.groupEnd();
  }, [exe]);

  useEffect(() => {
    if (!steps || !nextAnswer || verifying || !isArchSelected) return;

    setVerifying(true);
    (async () => {
      await insertNextAnswer();
    })();
  }, [nextAnswer]);

  return (
    <Elem>
      <Dots />
      {/* <button onClick={nextExercise}>next</button> */}
      <Container>
        <div className="inner">
          <div>
            {isArchSelected && <ArchesFirstStep />}
            <ArchesDown />
            {rowOne &&
              [...Array(1)].map((_, i) => (
                <Row key={`ro-${i}`}>
                  {rowOne.map((elem, ii) => (
                    <Slot key={`ar-${i}-${ii}`} />
                  ))}
                </Row>
              ))}
            {rowOne &&
              [...Array(1)].map((_, i) => (
                <Row key={`ro-${i}`}>
                  {rowOne.map((elem, ii) => {
                    if (typeof elem !== "object")
                      return (
                        <SlotRowOne col={ii} key={`ro-${i}-${ii}`}>
                          {elem}
                        </SlotRowOne>
                      );

                    if (elem?.type === "result")
                      return (
                        <Slot
                          key={`ro-${i}-${ii}`}
                          col={elem.j}
                          index={elem.index}
                        />
                      );

                    return <Cell key={`${i}-${ii}`}></Cell>;
                  })}
                </Row>
              ))}
            {steps &&
              [...Array(8)].map((_, i) => (
                <Row key={`g-${i}`}>
                  {[...Array(10)].map((_, j) => {
                    // const z = i * grid.rows + 1;
                    const elem = steps.find(
                      ({ type, row, col }) =>
                        type !== "result" && row === i && col === j
                    );

                    if (elem)
                      return (
                        <Slot
                          key={`g-${i}-${j}`}
                          row={i}
                          col={j}
                          index={elem.index}
                        />
                      );
                    return <Cell key={`${i}-${j}`}></Cell>;
                  })}
                </Row>
              ))}

            {!isArchSelected && <ArchSelection />}
          </div>
        </div>
      </Container>
    </Elem>
  );
}

const Row = styled.div`
  display: flex;
`;

const Elem = styled.div`
  position: relative;
  /* width: 50vw; */
  width: fit-content;
  height: ${vh(78)};
  background-color: #fff;
  top: 0;
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  /* padding: ${vh(4)}; */
  display: flex;
`;

const Dots = styled(DotsSvg)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  /* padding-top: 100%; */

  > .inner {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: calc(100% - (0.08 * var(--vh)));
    height: calc(100% - (0.08 * var(--vh)));
    padding: ${vh(4)};
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    gap: 20px;

    > div {
      border: 1px solid var(--c-grey);
      position: relative;
    }
  }
`;
