// @ts-ignore
import hintNo from "@/assets/img/ui/hint-no.svg";
// @ts-ignore
import hintYes from "@/assets/img/ui/hint-yes.svg";
// @ts-ignore
import resultNo from "@/assets/img/ui/result-no.svg";
// @ts-ignore
import resultYes from "@/assets/img/ui/result-yes.svg";
import { hintShowAtom, resultShowAtom } from "@/common/recoil";
import { vh } from "@/common/styled-components";
import { useRecoilState } from "recoil";
import styled from "styled-components";

export default function Toolbar({ showHint = true, showResult = true }) {
  return (
    <Elem className="toolbar">
      {showResult && (
        <Toggle
          c="result"
          whatAtom={resultShowAtom}
          icons={[resultYes, resultNo]}
        />
      )}
      {showHint && <Toggle whatAtom={hintShowAtom} icons={[hintYes, hintNo]} />}
    </Elem>
  );
}

const Elem = styled.div`
  position: absolute;
  z-index: 2;
  height: ${vh(13)};
  bottom: ${vh(-12)};
  width: 100%;
  margin-top: ${vh(4)};
  display: flex;
  align-items: center;
  gap: ${vh(4)};

  @media (min-aspect-ratio: 16/9) {
    left: ${vh(12)};
  }
`;

const Toggle = ({ whatAtom, icons, c = "" }) => {
  const [what, setWhat] = useRecoilState(whatAtom);

  return (
    <Container>
      <Button className={c} disabled={what} onClick={() => setWhat(true)}>
        {icons[0] && <img src={icons[0]} alt="" />}
      </Button>
      <Button className={c} disabled={!what} onClick={() => setWhat(false)}>
        {icons[1] && <img src={icons[1]} alt="" />}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const Button = styled.button`
  border: 0px;
  width: ${vh(6)};
  height: ${vh(6)};
  margin-right: ${vh(2)};
  border-radius: ${vh(2)};
  background-color: var(--c-main);
  color: var(--c-blue);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:disabled) {
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  &:disabled {
    background-color: rgba(255, 255, 255, 0.5);
  }

  img {
    width: ${vh(4.5)};
    height: ${vh(4.5)};
  }
`;
