import { vh } from "@/common/styled-components";
import styled from "styled-components";

export default function PaperDivision({ currentOperator }) {
  return (
    <PaperDivisionContainer>
      <Column key={1} op={currentOperator} from={1} len={5 * currentOperator} />
      <Column
        key={2}
        op={currentOperator}
        from={5 * currentOperator + 1}
        len={5 * currentOperator}
      />
    </PaperDivisionContainer>
  );
}

const Column = ({ from, len, op }) => {
  return (
    <ColumnContainer>
      {Array.from({ length: len }, (_, i) => i + from).map((x) => {
        const isNum = x % op === 0;
        return (
          <div key={Math.random()} className={`cell ${isNum && "num"}`}>
            <div>{isNum ? x : "•"}</div>
          </div>
        );
      })}
    </ColumnContainer>
  );
};

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 ${vh(2, false)};

  .cell {
    line-height: 0.5;
    text-align: center;
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 2em;
    font-size: ${vh(2)};

    > div {
        width: 100%;
    }
    
    &:not(.num) {
        > div {
            transform: scale(1.3);
            transform-origin: center;
        }
    }

    &.num {
        height: 1.5em;
    }
  }
`;

const PaperDivisionContainer = styled.div`
  /* padding: 0 ${vh(4)}; */
  height: 100%;
  display: flex;
  flex-direction: row;
  /* gap: 4vw; */
`;
