import { gameTitleAtom, menuIsOpenAtom } from "@/common/recoil";
import Calculator from "@/components/Calculator";
import Sheet from "@/components/games/DivisioniGame/Sheet";
import Game from "@/components/games/Game";
import Workbook from "@/components/Workbook";
import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

export default function DivisioniGame() {
  const menuIsOpen = useRecoilValue(menuIsOpenAtom);
  const gameTitleSet = useSetRecoilState(gameTitleAtom);
  const operators = [2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    //  TODO: Creare selector recoil con oggetto config.game
    gameTitleSet("Divisioni");
  }, []);

  return (
    <Game>
      <Workbook operators={operators} division={true} />
      <Sheet />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "flex-end"
      }}>
        <Calculator />
        {/* <Dev /> */}
      </div>
    </Game>
  );
}
