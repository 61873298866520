import { gameTitleAtom } from "@/common/recoil";
import Page from "@/components/Page";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

const Crediti = () => {

    const gameTitleSet = useSetRecoilState(gameTitleAtom);

    useEffect(() => {
       gameTitleSet("Crediti");
    }, [])

    return <>
        <Page>
            <div className="row justify-content-center">
                <div className="col-12 col-lg-6">
                    <h4>Tabelline digitali</h4>
                    Di Camillo Bortolato
                    <br /><br /><br />
                    © 2022 Edizioni Centro Studi Erickson S.p.A
                    <br /><br /><br />
                    Tutti i diritti riservati. Vietata la riproduzione con qualsiasi mezzo effettuata, se non previa autorizzazione dell’Editore.
                    <br /><br />
                    <h4>Centro Studi Erickson</h4>
                    Il Centro Studi Erickson è stato fondato nel 1984 sull’esperienza di un Centro Studi sui problemi della riabilitazione e dell’inserimento sociale delle persone con disabilità mentale, promosso fin dal 1979 da due specialisti di questa materia, gli psicologi Dario Ianes e Fabio Folgheraiter. Poiché per il lavoro di recupero e integrazione, che già impegnava un notevole numero di operatori, mancavano una cultura specifica e strumenti operativi adeguati, il Centro Studi Erickson ha svolto un’opera pionieristica di documentazione di esperienze e metodologie del mondo anglosassone (Gran Bretagna e USA), ed è nata così un’attività editoriale e di formazione che ora, grazie principalmente alla elevata qualificazione tecnica, ha acquisito una posizione di rilievo nel contesto nazionale. Il Centro Studi Erickson si occupa di didattica, educazione, psicologia, lavoro sociale e welfare attraverso la produzione di libri, riviste, software didattici, strumenti compensativi e servizi multimediali online. Pubblicazioni molto conosciute e apprezzate, perché affiancano la presentazione scientificamente rigorosa di teorie e metodologie innovative a suggerimenti operativi, studi di caso e buone prassi.
                    <br /><br />
                    <h4>Progettazione e sviluppo</h4>
                    <ul>
                        <li>PROGETTAZIONE TECNICA Daniele De Martin, Martina Pancheri </li>
                        <li>ATTIVITÀ DI CONSULENZA E SUPERVISONE ALLO SVILUPPO Patrizia Pigato </li>
                        <li>SVILUPPO SOFTWARE Hg blu </li>
                        <li>ILLUSTRAZIONI, GRAFICA E ANIMAZIONI Enrico Bortolato, Silvia Benedetti,&nbsp;Antonella Gozzi</li>
                    </ul>
                    <h4>Copyrights</h4>
                    <ul>
                        <li>EFFETTO SONORO DELLA SEZIONE PROVA https://www.zapsplat.com</li>
                    </ul>
                </div>
            </div>
        </Page>

    </>
}

export default Crediti