import styled from "styled-components";
import { vh } from "../common/styled-components";
import Menu from "../components/MenuContainer/Menu";

const Home = () => {
    // const { headerHiddenUpdate, colorsUpdate } = useGameContextUpdate()

    // useEffect(() => {
    //     headerHiddenUpdate(true)
    //     colorsUpdate({
    //         main: "#fef7ec"
    //     })
    // }, [])
    return (
        <div className="col-12 text-center h-100 px-0">
            <Menu fullpage />
        </div>
    )
}



export default Home

const Container = styled.div`
    height: ${vh(90)};
`