import { nextAnswerAtom } from "@/common/recoil";
import { atom, atomFamily, selector } from "recoil";

export const exeAtom = atom({
  key: "moltiplicazioni_exe",
  default: null,
});

export const exeIndexAtom = atom({
  key: "moltiplicazioni_exeIndex",
  default: 0,
});

export const slotsValues = atomFamily({
  key: "slotsValues",
  default: null,
});

export const slotsKeys = atom({
  key: "slotsKeys",
  default: [],
});

export const slotsMapAtom = atom({
  key: "slotsMap",
  default: [],
});

export const currentSlotNAtom = atom({
  key: "currentSlotN",
  default: 0,
});

export const currentSlotPosAtom = atom({
  key: "currentSlotPos",
  default: [0, 0],
});

export const hasSumAtom = atom({
  key: "moltiplicazioni_has_sum",
  default: false,
});

export const currentSlotPropsSelector = selector({
  key: "currentSlotProps",
  get: ({ get }) => {
    const currentSlotN = get(currentSlotNAtom);
    const nextAnswer = get(nextAnswerAtom);
    const slotsMap = get(slotsMapAtom);
    const answerMap = get(answerMapAtom);
    const hasSum = get(hasSumAtom);
    const exe = get(exeAtom);

    const isMap = slotsMap.map((row) => row.includes(currentSlotN));
    const mapRow = isMap.indexOf(true);

    const slotIndex = slotsMap[mapRow].indexOf(currentSlotN);

    const is2fromLast = slotIndex === slotsMap[mapRow].length - 3;
    const isLast = slotIndex === slotsMap[mapRow].length - 1;

    const isLastLast = mapRow === slotsMap.length - 1 && isLast;

    // console.log("rowHasLastInversion", answerMap[mapRow].length, String(get(exeAtom)[0]).length)
    // const rowHasLastInversion = answerMap[mapRow].length > String(get(exeAtom)[0]).length
    const rowHasLastInversion = (() => {
      if (isMap.length > 1) {
        // se c'è somma
        if (mapRow < isMap.length - 1) {
          // se non è l'ultima riga
          return answerMap[mapRow].length > String(get(exeAtom)[0]).length;
        } else {
          // se è l'ultima riga => somma
          const sumLen = slotsMap.slice(-1)[0].length
          let sliced = answerMap.slice(0, -1)
          sliced = sliced.map((row, i) => (
            [...row, ...Array(i).fill()]
          ))
          // sliced[sliced.length - 1] = [...sliced[sliced.length - 1], 0]
          console.log("sliced", sliced, );
          

          const longest = sliced.reduce((acc, row) => acc > row.length ? acc : row.length, 0)
          console.log("sumLen", sumLen, "longest", longest);
          return sumLen > longest;
        }
      } else {
        // non c'è somma
        console.log(
          "NO SOMMA",
          answerMap[mapRow].length > String(get(exeAtom)[0]).length
        );
        return answerMap[mapRow].length > String(get(exeAtom)[0]).length;
      }
    })();

    return {
      slotIndex,
      mapRow,
      is2fromLast,
      isLast,
      isLastLast,
      rowHasLastInversion,
    };
  },
});

export const answerAtom = atom({
  key: "answer",
  default: null,
});

export const answerMapAtom = atom({
  key: "answerMap",
  default: null,
});

export const isVerifyingAtom = atom({
  key: "isVerifying",
  default: false,
});
