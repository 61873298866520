export * from "./game";

import { atom } from "recoil";

export const hamburgerHiddenAtom = atom({
    key: 'hamburgerHidden',
    default: false
})

export const menuIsOpenAtom = atom({
    key: 'menuIsOpen',
    default: false
})


export const playSoundRightAtom = atom({
    key: "play_sound_right",
    default: null
})

export const playSoundRightLongAtom = atom({
    key: "play_sound_right_long",
    default: null
})

export const playSoundWrongAtom = atom({
    key: "play_sound_wrong",
    default: null
})

export const playSoundGameOverAtom = atom({
    key: "play_sound_game_over",
    default: null
})