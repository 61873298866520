import { currentOperatorAtom } from "@/common/recoil";
import { vh } from "@/common/styled-components";
import config from "@/config";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled, { createGlobalStyle, css } from "styled-components";

export default function Labels({ division, operators, onClickFn }) {
  const currentOperator = useRecoilValue(currentOperatorAtom);
  const [ops, setOps] = useState();

  useEffect(() => {
    if (!operators) return;
    let o = operators.map((n) => ({
      ...config.operators[n],
      number: n,
    }));

    if (division) o = o.reverse();

    setOps(o);
  }, [operators]);

  return (
    <>
      <LabelsGlobal />
      <Elem>
        <div className="labels" data-division={division}>
          {ops &&
            ops.map(({ number, color }, i) => (
              <Label
                key={i}
                // @ts-ignore
                current={number === currentOperator}
                color={color}
                onClick={() => onClickFn(number)}
              >
                <span>{number}</span>
              </Label>
            ))}
        </div>
      </Elem>
    </>
  );
}

const Label = styled.button`
  border: 0px;
  margin: 4px 0;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: ${vh(3)};
  color: #fff;
  text-align: left;
  padding-left: ${vh(2)};
  cursor: pointer;
  font-family: var(--font-museo);
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -50%;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    background-color: inherit;
    display: none;
    z-index: 0;
  }

  > span {
    position: relative;
    z-index: 10;
  }

  /* &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  } */

  ${(p) =>
    // @ts-ignore
    p.current &&
    css`
      background-color: ${(p) =>
        // @ts-ignore
        p.color};
      &:before {
        display: block;
      }
    `}
`;

const Elem = styled.div``;

const LabelsGlobal = createGlobalStyle`
  .labels {
    --border-radius: 50%;
    position: absolute;
    z-index: 10;
    height: calc(100% + 8px);
    top: -4px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: calc(var(--padding) * 4);
    right: calc(var(--padding) * -4);

    &[data-division="true"] {
      --border-radius: 50%;
        justify-content: end;
        bottom: 0;
        display: flex;
        flex-direction: column-reverse;

        ${Label} {
          height: ${vh(6)};
          width: ${vh(6)};
          margin: 2px 0;
          
          /* &:last-of-type {
            margin-bottom: 0;
          } */

          &:first-of-type {
            margin-bottom: 4px;
          }
        }
    }

  }
`;
