import { nextAnswerAtom } from "@/common/recoil";
import { vh } from "@/common/styled-components";
import useGameFunctions from "@/components/games/MoltiplicazioniGame/hooks/useGameFunctions";
import {
  answerAtom,
  answerMapAtom,
  currentSlotNAtom,
  exeAtom,
  hasSumAtom,
  isVerifyingAtom,
  slotsMapAtom
} from "@/components/games/MoltiplicazioniGame/recoil";
import Slot, { SlotElem } from "@/components/games/MoltiplicazioniGame/Slot";
import Sum from "@/components/games/MoltiplicazioniGame/Sum";
import config from "@/config";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";

export default function Grid2() {
  const { level, game } = useParams();
  const { nextSlotN, insertNextAnswer, isExeEnded, nextExercise } = useGameFunctions();

  // RECOIL
  const nextAnswer = useRecoilValue(nextAnswerAtom);
  const currentSlotN = useRecoilValue(currentSlotNAtom);
  const setSlotsMap = useSetRecoilState(slotsMapAtom);
  const [exe, setExe] = useRecoilState(exeAtom);
  const [answer, setAnswer] = useRecoilState(answerAtom);
  const [answerMap, setAnswerMap] = useRecoilState(answerMapAtom);
  const [verifying, setVerifying] = useRecoilState(isVerifyingAtom);

  // STATE
  // const [answer, setAnswer] = useState(null);
  const [hasSum, setHasSum] = useRecoilState(hasSumAtom)

  // ON LEVEL CHANGE
  useEffect(() => {
    setExe(config.games.moltiplicazioni.levels[level].exercises[0]);
  }, [level]);

  // ON EXERCISE CHANGE
  useEffect(() => {
    if (!exe) return;
    console.group("NEW EXE", exe);
    console.log("answer", answer, exe[0], exe[1]);

    const multipliers = String(exe[1])
      .split("")
      .map((m) => Number(m))
      .reverse();

    let ans = multipliers
      .reverse()
      .map((mult) => mult * exe[0])
      .map((n) =>
        String(n)
          .split("")
          .reverse()
          .map((n) => Number(n))
      )
      .reverse();

    // PUSH SUM
    if (ans.length > 1) {
      console.log("PUSH SUM IN MAP");
      // let aa = ans.slice();
      // aa.sort((a, b) => {
      //   return a.length - b.length;
      // }).reverse()
      // console.log("AA", aa)

      // let sum = 0;
      // ans.forEach((a) => {
      //   sum += a.reduce((a, b) => a + b);
      // });
      // console.log("SUM", ans);

      let ansClone = ans.slice();
      // console.log("ANS", ans);
      let sum = ansClone
        .map((r) => r.slice().reverse().join(""))
        .map((x) => Number(x))
        .map((x, i) => x * 10 ** i);

      console.log("PRESUM", sum);

      sum = sum.reduce((a, b) => a + b);

      ans.push(
        String(sum)
          .split("")
          .reverse()
          .map((x) => Number(x))
      );

      console.log("SUM", sum);
      // ans.push([9, 9, 9, 9]);
    }

    let ansMapN = -1;
    let ansMap = ans.map((row) => {
      return row.map((cell) => {
        ansMapN++;
        return ansMapN;
      });
    });
    // ansMap = ansMap.filter((r, i) => i < ansMap.length - 1)

    setAnswer(ans.flat());
    setAnswerMap(ans);
    setSlotsMap(ansMap);
    setHasSum(ansMap.length > 1);

    console.log("ANSWER", ans);
    console.log("ansMap", ansMap);
    console.log("ansMap.length", ans.slice(-1)[0].length);

    console.groupEnd();
  }, [exe]);

  // ON NEXT ANSWER (aka calculator button press)
  useEffect(() => {
    if (!nextAnswer || verifying) return;

    setVerifying(true);
    (async () => {
      await insertNextAnswer({});
      const isEnded = await isExeEnded()
      
      if (!isEnded) return
      console.log("DONE");
      nextExercise();
    })();
  }, [nextAnswer]);

  useEffect(()=> {
    console.log("ANSWER", answer)
  }, [answer])

  let n = -1;

  return (
    <>
      <Elem>
        <Section>
          <div style={{ marginLeft: "auto" }}>
            {exe &&
              exe.map((row, i) => (
                <Row key={i}>
                  {String(row)
                    .split("")
                    .reverse()
                    .map((cell, i) => (
                      <SlotElem key={i}>{cell}</SlotElem>
                    ))}
                </Row>
              ))}
            <Line />
            {/* <Answer>
          {answer &&
            answer.map((slot, i) => (
              <Slot
                className={`answer-slot ${i === nAnswer && "current"}`}
                answer={slot}
                n={i}
                key={i}
              ></Slot>
            ))}
        </Answer> */}
            {answerMap &&
              answerMap
                .filter((r, i) => !(answerMap.length > 1) || (i < answerMap.length - 1))
                .map((line, i) => (
                  <Answer key={i}>
                    {Array(i)
                      .fill(0)
                      .map((s, x) => (
                        <SlotElem key={x} className="answer-slot">
                          -
                        </SlotElem>
                      ))}

                    {line.map((slot, j) => {
                      n++;
                      const isCurrent = n === currentSlotN;
                      return (
                        <Slot
                          className={`answer-slot ${isCurrent && "current"}`}
                          answer={slot}
                          n={n}
                          key={j}
                        />
                      );
                    })}
                  </Answer>
                ))}
          </div>
          <Signs>
            <SlotElem>x</SlotElem>
            <SlotElem>=</SlotElem>
          </Signs>
        </Section>
        {answerMap && hasSum && (
          <>
            <Line style={{
              width: "calc(100% - var(--division-cell-x) - 5px)"
            }} />
            <Section>
              <Sum n={n} sumAns={answerMap[answerMap.length - 1]} />
            </Section>
          </>
        )}
      </Elem>
      {/* <div className="position-relative">
          {hasSum && <Sum sumAns={answerMap[answerMap.length - 1]} />}
      </div> */}
    </>
  );
}

const Elem = styled.div`
  /* position: absolute; */
  top: var(--game-padding);
  right: var(--game-padding-x);
  display: flex;
  flex-direction: column;

  --cell-x: ${vh(6)};
`;
const Section = styled.div`
  display: flex;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const Answer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const Line = styled.div`
  height: 2px;
  width: 100%;
  background-color: #fff;
  margin-top: ${vh(1)};
  margin-bottom: ${vh(1)};
`;

const Signs = styled.div`
  /* ${SlotElem} {
    font-size: ${vh(3)};
  } */
`;
