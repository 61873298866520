import { ants, hints, menuIcons } from "@/config";

const images = {
  ...menuIcons,
  ...hints,
  ...ants,
};
const keys = Object.keys(images);
export default function usePrecache() {
  const precacheImages = () => {
    console.log("loading images", menuIcons);

    Promise.all([
      ...keys.map((i) => {
        return new Promise((res) => {
          const img = new Image();
          img.onload = () => res();
          img.src = images[i];
        });
      }),
    ]).then((p) => {
      console.log("images loaded");
    });
  };

  return {
    precacheImages,
  };
}
