import { TOKEN_ANON, TOKEN_BEARER } from "@/constants/auth";
import { t } from "@/constants/translations";
import { URL_HOME_MAB, URL_ISLOGGED_API } from "@/constants/urls";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import s from "./auth.module.css";

const authStatus = {
  loading: "loading",
  true: true,
  false: false,
};

export const Auth = (props) => {
  const [verified, setAuth] = useState(authStatus.loading);
  const [notUsable, setNotUsable] = useState(false);
  const MIN_HEIGHT = 420;

  useEffect(() => {
    if (props.dev) return;

    // Handle token
    checkAuth();

    // TODO: ONLY FOR DEV (skip login)
    // Handle token
    //checkAuth();
    //setAuth(authStatus.true);
  }, []);

  const resize = useCallback(() => {
    const root = window;

    if (root && window.innerWidth >= 900 && window.innerHeight >= MIN_HEIGHT) {
      setNotUsable(false);
    } else {
      setNotUsable(true);
    }
  }, []);

  useEffect(() => {
    resize();

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  // Handle auth token
  const checkAuth = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const authToken = urlParams.get("t");

    if (authToken) {
      try {
        const request = await fetch(
          `${URL_ISLOGGED_API}/?t=${
            process.env.NODE_ENV === "production" ? authToken : TOKEN_ANON
          }`,
          {
            headers: { Authorization: `Bearer ${TOKEN_BEARER}` },
          }
        );
        const response = await request.json();
        if (response.hasErrors) {
          throw response.errorDescription;
        }
        setAuth(authStatus.true);
      } catch (error) {
        setAuth(authStatus.false);
        window.location.href = URL_HOME_MAB;
      }
    } else {
      setAuth(authStatus.false);
      window.location.href = URL_HOME_MAB;
    }
  };

  if (props.dev) return <>{props.children}</>;

  return (
    <Container>
      {verified === authStatus.true ? (
        // hiding elements prevent comp. remount, the children state are preserved
        <React.Fragment>
          {notUsable && (
            <div
              className={`container-fluid align-items-center w-100 h-100 ${s.resolution_warning__container}`}
            >
              <h1 className={s.resolution_warning}>{t.warningSmallScreen}</h1>
            </div>
          )}

          {!notUsable && <div className={`w-100 h-100`}>{props.children}</div>}
        </React.Fragment>
      ) : null}
      {verified === authStatus.false ? (
        <div>
          <h1>
            Non puoi vedere questa pagina, <br /> riprova ad autenticarti.
          </h1>
        </div>
      ) : null}
      {verified === authStatus.loading ? (
        <div className={s.loading}>
          <div className={s.ldsRipple}>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  color: var(--c-main);
`