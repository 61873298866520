// @ts-ignore
import archDown from "@/assets/img/arches/archDown.svg";
import { vh } from "@/common/styled-components";
import { stepAtom, stepsAtom } from "@/components/games/DivisioniGame/recoil";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

export default function ArchesDown() {
  const steps = useRecoilValue(stepsAtom);
  const step = useRecoilValue(stepAtom);
  // // const currentStep = useRecoilValue(stepsValuesFamily(step))

  const [downSteps, setDownSteps] = useState([]);

  useEffect(() => {
    if (!steps) return;
    console.log("steps", steps);
    setDownSteps(steps.filter((step) => step.type === "down"));

    return () => {
      setDownSteps([]);
    }
  }, [steps]);

  return (
    <Elem>
      {downSteps.map((s, index) => {
        return (
          <Arch key={index} data-index={s.col} data-show={s.index <= step - 1}>
            <img src={archDown} />
          </Arch>
        );
      })}
    </Elem>
  );
}

const Elem = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  transform: translateY(calc(var(--division-cell-x) - 100%));
  width: 100%;
  height: 20px;
  z-index: 100;
  text-align: center;

  img {
    height: ${vh(1)}
  }
`;

const Arch = styled.div`
  width: var(--division-cell-x);
  height: 100%;
  // prettier-ignore
  transform: translateX(calc(${(p) =>
    p["data-index"]} * var(--division-cell-x) + ${(p) =>
    p["data-index"] - 1} * 5px));
  position: absolute;
  opacity: ${(p) => (p["data-show"] ? 1 : 0)};

  img {
    /* transform: rotate(180deg); */
  }
`;
