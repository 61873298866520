import { answerCountAtom, gameConfigAtom, hintShowAtom, playSoundGameOverAtom, resultShowAtom } from "@/common/recoil";
import { vh } from "@/common/styled-components";
import DivisioniGame from "@/components/games/DivisioniGame";
import MoltiplicazioniGame from "@/components/games/MoltiplicazioniGame";
import ProvaGame from "@/components/games/ProvaGame";
import { antAnimationAtom, raceStateAtom } from "@/components/games/ProvaGame/recoil";
import StudiaGame from "@/components/games/StudiaGame/index";
import Header from "@/components/Header";
import config from "@/config";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";

const Game = () => {
  // PARAMS
  // @ts-ignore
  const { game, id } = useParams();
  // CONTEXT
  // const { helpersUpdate, fieldsUpdate, matrixUpdate, pageImageUpdate, antUpdate } = usePageContextUpdate()
  // const { colorsUpdate, sideNavigationUpdate, headerHiddenUpdate, hamburgerHiddenUpdate, headerTitleUpdate } = useGameContextUpdate()
  const setGameConfig = useSetRecoilState(gameConfigAtom);
  const setAntAnimation = useSetRecoilState(antAnimationAtom);
  const setRaceState = useSetRecoilState(raceStateAtom);
  const setHintShow = useSetRecoilState(hintShowAtom);
  const setResultShow = useSetRecoilState(resultShowAtom);
  const setAnswerCount = useSetRecoilState(answerCountAtom)

  
  const [playSoundGameOver, setplaySoundGameOver] = useRecoilState(playSoundGameOverAtom);

  useEffect(() => {
    // headerHiddenUpdate(false)
    // hamburgerHiddenUpdate(false)
  }, []);

  useEffect(() => {
    if (!game) return;
    // headerTitleUpdate(config.games[game].title)
    // document.querySelector(".sidebar").scrollTo(0,0)
    setGameConfig(config.games[game]);
    console.log("game", game);

    setHintShow(true);
    if (game !== "prova") setResultShow(true);

    setAnswerCount(0)
    
  }, [game]);

  useEffect(() => {
    console.log("🎵 playSoundGameOver", playSoundGameOver);
  }, [playSoundGameOver]);

  useEffect(() => {
    // const pageObject = config.games[game].pages[Number(id) - 1]
    // const pageObject = config.games[game].pages.filter(p => p.id === Number(id))[0]
    // console.log("PO", pageObject)
    // colorsUpdate(config.games[game].colors)
    // helpersUpdate(pageObject.helpers)
    // fieldsUpdate(pageObject.fields.items)
    // matrixUpdate(pageObject.fields.matrix)
    // pageImageUpdate(pageObject.image)
    // sideNavigationUpdate(config.games[game].pages.map(p => {
    //     return {
    //         img: p.thumb,
    //         // title: p.title,
    //         title: `Esercizio ${p.alias}`,
    //         url: `/${game}/${p.id}`,
    //         current: (Number(id) === p.id)
    //     }
    // }))
    // antUpdate({
    //     icon: config.games[game].helpIcon,
    //     audio: pageObject.audio
    // })
  }, [id, game]);

  return (
    <>
      <Header />
      <Container>
        {game === "studia" && <StudiaGame />}
        {game === "prova" && <ProvaGame />}
        {game === "moltiplicazioni" && <MoltiplicazioniGame />}
        {game === "divisioni" && <DivisioniGame />}
        {/* <Sidebar /> */}
        {/* <AntContainer /> */}
        {/* <PaperContainer /> */}
        {/* <CalculatorContainer /> */}
        {/* <AudioFeedbacks /> */}
      </Container>
    </>
  );
};

export default Game;

const Container = styled.div`
  position: fixed;
  left: 0;
  top: ${vh(10)};
  width: 100%;
  height: ${vh(90)};
`;
