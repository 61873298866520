import { sleep } from "@/common/functions";
import { nextAnswerAtom, playSoundRightAtom, playSoundWrongAtom } from "@/common/recoil";
import useGlobalGameFunctions from "@/common/useGlobalGameFunctions";
import {
  exeAtom,
  isArchSelectedAtom,
  isVerifyingAtom,
  nExeAtom,
  rowOneAtom,
  stepAtom,
  stepsAtom,
  stepsValuesFamily,
  stepsValuesLenAtom
} from "@/components/games/DivisioniGame/recoil";
import config from "@/config";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilCallback } from "recoil";

export default function useGameFunctions() {
  const { game, level } = useParams();
  const navigate = useNavigate();
  const { rightAnswerSound } = useGlobalGameFunctions()

  const objectGenerate = (exe) => {
    const [dividend, divisor, arch] = exe;

    const results = _resultsGenerate({ dividend, divisor, arch });
    const steps = _stepsGenerate({ dividend, divisor, arch });
    const grid = _gridGenerate(steps.values);
    const gridResult = _gridGenerate(
      steps.values.filter((v) => v.type === "result")
    );
    const gridDividend = _gridDividend(dividend);

    return {
      data: {
        dividend,
        divisor,
        arch,
      },
      results,
      steps,
      grid,
      gridResult,
      gridDividend,
    };
  };

  const _resultsGenerate = ({ dividend, divisor, arch }) => {
    dividend = Number(dividend);
    return {
      dividend,
      divisor,
      result: Math.floor(dividend / divisor),
      remainder: dividend % divisor,
    };
  };

  // TODO: divisore a 3 cifre

  const _stepsGenerate = ({ dividend, divisor, arch }) => {
    const count = String(dividend).length;
    let steps = [],
      values = [],
      index = 0;

    // PRIMO STEP
    let step = 0;
    let _dividend = String(dividend).substring(0, arch);
    const stepsCount = count - arch + 1;

    steps[step] = _resultsGenerate({
      dividend: _dividend,
      divisor,
      arch,
    });

    values.push({
      type: "result",
      value: steps[step].result,
      row: -1,
      col: step,
      index: index++,
    });

    values.push({
      type: "remainder",
      value: steps[step].remainder,
      row: step,
      // col: step + 1, // controllo archetto
      col: arch - 1, // controllo archetto
      index: index++,
    });

    for (let i = arch; i < count; i++) {
      step++;

      _dividend =
        steps[step - 1].remainder + String(dividend).substring(i, i + 1);

      values.push({
        type: "down",
        value: Number(String(dividend).substring(i, i + 1)),
        row: step - 1,
        col: arch === 1 ? step : step + 1, // TODO: da migliorare
        index: index++,
      });

      steps[step] = _resultsGenerate({
        dividend: Number(_dividend),
        divisor,
        arch,
      });

      values.push({
        type: "result",
        value: steps[step].result,
        row: -1, // SICURO ???
        col: step,
        index: index++,
      });

      values.push({
        type: "remainder",
        value: steps[step].remainder,
        row: step,
        col: arch === 1 ? step : step + 1, // TODO: da migliorare
        index: index++,
      });
    }

    return {
      data: steps,
      values,
    };
  };

  const _gridGenerate = (values) => {
    const cols = Math.max(...values.map((v) => v.col)) + 1;

    const rows = Math.max(...values.map((v) => v.row)) + 1;

    return {
      cols,
      rows,
    };
  };

  const _gridDividend = (dividend) => {
    const count = String(dividend).length;

    return {
      rows: 1,
      cols: count,
    };
  };

  const _checkNextAnswer = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const steps = await snapshot.getPromise(stepsAtom);
        const step = await snapshot.getPromise(stepAtom);
        const nextAnswer = await snapshot.getPromise(nextAnswerAtom);
        const { value } = steps[step];

        console.log("CHECK", nextAnswer, value);

        return String(nextAnswer) === String(value);
      },
    []
  );

  const _undoAnswer = useRecoilCallback(
    ({ snapshot, set }) =>
      async (step) => {
        set(stepsValuesFamily(step), (curr) => ({
          ...curr,
          right: false,
          pending: false,
        }));
      },
    []
  );

  const _nextStep = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const step = await snapshot.getPromise(stepAtom);
        const steps = await snapshot.getPromise(stepsAtom);
        // const currentGameConfig = await snapshot.getPromise(
        //   currentGameConfigState
        // );
        console.group("NEXT STEP");
        console.log("step", `${step}/${steps.length}`);
        console.groupEnd();

        if (step < steps.length) {
          set(stepAtom, (s) => s + 1);
        }
      },
    []
  );

  const setFamilyMember = useRecoilCallback(
    ({ snapshot, set }) =>
      (id, val) => {
        set(stepsValuesFamily(id), val);
      },
    []
  );

  const insertNextAnswer = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const step = await snapshot.getPromise(stepAtom);
        set(stepsValuesFamily(step), (curr) => ({ ...curr, pending: true }));

        const isRight = await _checkNextAnswer();
        console.log("isRight", isRight);
        await sleep(500);
        // await sleep(1);

        if (isRight) {
          // set(playSoundRightAtom, new Date())
          set(stepsValuesFamily(step), (curr) => ({
            ...curr,
            right: true,
            pending: false,
          }));
          const isEnded = await _isExeEnded();
          console.log("ENDED?", isEnded);

          if (!isEnded) {
            await _nextStep();
          } else {
            console.log("DONE");
            // await clearExe();
            nextExercise();
          }
        } else {
          set(playSoundWrongAtom, new Date())
          console.log("UNDO", step);
          await _undoAnswer(step);
        }
        set(nextAnswerAtom, null);
        set(isVerifyingAtom, false);
      },
    []
  );

  const _isExeEnded = useRecoilCallback(({ snapshot, set }) => async () => {
    const steps = await snapshot.getPromise(stepsAtom);
    const step = await snapshot.getPromise(stepAtom);
    return step === steps.length - 1;
  });

  const clearExe = useRecoilCallback(({ snapshot, set }) => async () => {
    const stepsValuesLen = await snapshot.getPromise(stepsValuesLenAtom);
    for (let i = 0; i < stepsValuesLen; i++) {
      set(stepsValuesFamily(i), null);
    }
    set(stepsValuesLenAtom, null);
    set(stepAtom, null);
    set(stepsAtom, null);

    set(isArchSelectedAtom, false);

    set(rowOneAtom, null);
    // set(nExeAtom, 0)
    console.log("🧹 EXE CLEARED")
  });

  const nextExercise = useRecoilCallback(({ snapshot, set }) => async () => {
    const exeIndex = await snapshot.getPromise(nExeAtom);
    const currentGameConfig = config.games.divisioni
    const newExeIndex = exeIndex + 1;
    const {ms} = await rightAnswerSound()

    await sleep(ms);
    
    await clearExe();

    // await clearExe();
    if (exeIndex < currentGameConfig.levels[level].exercises.length - 1) {
      /**
       * PROSSIMO ESERCIZIO
       */
      console.log("• NUOVO EXE");
      set(exeAtom, currentGameConfig.levels[level].exercises[newExeIndex]);
      set(nExeAtom, newExeIndex);
    } else {
      /**
       * PROSSIMO LIVELLO
       */
      console.log("• PROSSIMO LIVELLO •");
      const levels = Object.keys(currentGameConfig.levels);
      const nextLevel = levels[levels.indexOf(level) + 1];
      if (nextLevel) {
        navigate(`/${game}/${nextLevel}`);
      } else {
        navigate("/home")
      }
    }
  });

  return {
    objectGenerate,
    setFamilyMember,
    insertNextAnswer,
    nextExercise,
    clearExe,
  };
}
