import { vh } from "@/common/styled-components";
import Header from "@/components/Header";
import styled from "styled-components";

const Page = ({ children }) => {
  return (
    <>
      <Header backBtn={true} />
      <Container>
        <div className="inner">
          {children}
        </div>
        {/* <div>{children}</div> */}
      </Container>
    </>
  );
};

export default Page;

const Container = styled.div`
  /* position: fixed; */

  overflow-y: scroll;

  left: 0;
  top: ${vh(10)};
  width: 100%;
  height: ${vh(90)};
  font-size: ${vh(2)};
  color: var(--c-main);

  @media screen and (max-width: 1600px) {
    font-size: ${vh(4)};
  }


  > .inner {
    margin: auto;
    width: 100%;
    max-width: min(90vw, 100rem);
  }
`;
