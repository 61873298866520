import { randomProperty } from "@/common/functions";
import { calculatorRightPositionAtom } from "@/common/recoil";
import { vh } from "@/common/styled-components";
import {
  antAnimationAtom,
  antLoadedAtom,
  exeAtom,
  raceLeftPositionAtom,
  raceStartAtom,
  raceStateAtom
} from "@/components/games/ProvaGame/recoil";
import config, { animals } from "@/config";
import { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";

const Bird = ({ contRef }) => {
  const { delay, defaultDuration } = config.games.prova;
  
  const [raceStart, setRaceStart] = useRecoilState(raceStartAtom);
  
  const exe = useRecoilValue(exeAtom);
  const raceState = useRecoilValue(raceStateAtom);
  const calculatorRightPosition = useRecoilValue(calculatorRightPositionAtom);
  const raceLeftPosition = useRecoilValue(raceLeftPositionAtom);
  const setAntLoaded = useRecoilValue(antLoadedAtom);
  
  const setAntAnimation = useSetRecoilState(antAnimationAtom);
  
  const [offset, setOffset] = useState(0);
  const [animal, setAnimal] = useState();
  const [duration, setDuration] = useState(null);

  const calcOffset = () => setOffset(contRef.current.clientWidth);

  useEffect(() => {
    console.log("❌ BIRD INIT");
  }, []);

  // CALC OFFSET
  useEffect(() => {
    if (!contRef?.current || !calculatorRightPosition || !raceLeftPosition || !setAntLoaded) return;
    
    calcOffset();
    window.calc = calcOffset;

    window.addEventListener("resize", calcOffset);

    return () => window.removeEventListener("resize", calcOffset);
  }, [contRef, calculatorRightPosition, raceLeftPosition, setAntLoaded]);


  useEffect(() => {
    console.log("calcoffset", raceLeftPosition, calculatorRightPosition, offset);
  }, [offset])

  // ON EXE -> CHECK DURATION
  useEffect(() => {
    if (!exe) return;
    setDuration(exe[2] || defaultDuration);
    setAnimal(randomProperty(animals));
  }, [exe]);

  useEffect(() => {
    if (!raceState || !duration) return;

    switch (raceState) {
      case "running":
        (async () => {
          start();
        })();
        break;

      case "paused":
        animate.pause();
        break;

      case "win":
        animate.resume();

        // (async ()=> {
        //   await sleep(delay);
        //   console.log("BIUBIBIBIBI")
        // })()

        break;
    }
  }, [raceState, duration, offset]);
  // useEffect(() => {
  //   if (!raceStart || !duration) return;
  //   console.log("START", `${duration}ms`);
  //   start();
  // }, [raceStart, duration]);

  const [style, animate] = useSpring(() => ({
    // x: 400,
    height: "100%",
    right: 0,
    x: "0%",
    transform: "scale(1.5) translateX(0%)",
    width: "fit-content",
    position: "absolute",
    reset: true,
  }));

  const start = () => {
    animate({
      from: {
        right: 0,
        x: "0%",
        transform: "scale(1.5) translateX(0%)",
      },
      to: {
        right: offset,
        x: "100%",
        transform: "scale(1.5) translateX(10%)",
      },
      config: { duration: duration },
      onRest: () => {
        console.log("END");
        setRaceStart(false);
        setAntAnimation("die");
        // animate.stop();
        // animate.delete();
      },
    });
  };

  window.start = start

  // useEffect(()=> {
  //   window.addEventListener("resize", start);
  // }, [])

  return (
    <>
      <animated.div style={style}>
        <B>
          <img src={animal} alt="" />
        </B>
      </animated.div>
    </>
  );
};

const B = styled.div`
  height: 100%;
  aspect-ratio: 1 / 1;
  /* border: 1px solid #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center left;
  /* transform: scale(1.5) translateX(25%); */

  .operation {
    font-size: ${vh(2.5)};
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export default Bird;
