import { gameTitleAtom, menuIsOpenAtom } from "@/common/recoil";
import Calculator from "@/components/Calculator";
import Game from "@/components/games/Game";
import Grid2 from "@/components/games/MoltiplicazioniGame/Grid2";
import Toolbar from "@/components/Toolbar";
import Workbook from "@/components/Workbook";
import config from "@/config";
import { useEffect } from "react";
import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";

export const exerciseAtomFamily = atomFamily({
  key: "exerciseAtomFamily",
  default: [],
});

export default function MoltiplicazioniGame() {
  const menuIsOpen = useRecoilValue(menuIsOpenAtom);
  const gameTitleSet = useSetRecoilState(gameTitleAtom);
  const currentGameConfig = config.games.moltiplicazioni;

  useEffect(() => {
    gameTitleSet(config.games.moltiplicazioni.title);
  }, []);

  return (
    <Game>
      <Workbook operators={currentGameConfig.operators} />
      {/* <Grid /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Grid2 />
        <Calculator style={{ marginLeft: "auto" }} />
      </div>
      <Toolbar />
    </Game>
  );
}
