import { sleep } from "@/common/functions";
import { answerCountAtom, playSoundRightAtom, playSoundRightLongAtom } from "@/common/recoil";
import { useState } from "react";
import { useRecoilCallback, useRecoilState } from "recoil";

export default function useGlobalGameFunctions() {

    const rightAnswerSound = useRecoilCallback(
        ({ snapshot, set }) =>
            async () => {
                const answerCount = await snapshot.getPromise(answerCountAtom)
                const count = answerCount + 1;
                set(answerCountAtom, count)

                if (count % 5 === 0) {
                    set(playSoundRightLongAtom, new Date())
                    return { ms: 4000 }
                } else {
                    set(playSoundRightAtom, new Date())
                    return { ms: 1500 }
                }
                // set(playSoundRightAtom, new Date());
                // set(playSoundRightLongAtom, new Date());
            },
        []
    );

    return { rightAnswerSound };
}