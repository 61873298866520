import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// import "virtual:vite-plugin-sentry/sentry-config";

import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import App from "./App";
import "./index.css";

import "virtual:vite-plugin-sentry/sentry-config";

// now you can use this variable like so
const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist;
const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release;

// use it in sentry init
Sentry.init({
  // other sentry options
  dsn: "https://11ddd34f536542039869061cc2103e87@o513036.ingest.sentry.io/6509797",
  integrations: [
    new BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({ console: true }),
  ],
  tracesSampleRate: 1.0,
  dist,
  release,
});

// const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist
// const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release

// console.log("import.meta.env", import.meta.env);

// Sentry.init({
//   // other sentry options
//   dist,
//   release,
// });

// Sentry.init({
//   dsn: "https://11ddd34f536542039869061cc2103e87@o513036.ingest.sentry.io/6509797",
//   integrations: [new BrowserTracing(), new Sentry.Integrations.Breadcrumbs({ console: false })],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   release: process.env.RELEASE,
// });

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>
);

// reportWebVitals(console.log)
