import path from "path";

//// ITERAZIONI PER REQUIRE CONTEXT
export const i = (rc) => {
    const ia = rc.keys().map(x => ({
        name: path.basename(x).split('.').slice(0, -1).join('.'),
        import: rc(x)
    }))
    let out = {}
    ia.forEach(i => {
        out[i.name] = i.import.default
    });
    // console.log("OUT", out);
    return out
}

export const sleep = ms => new Promise(r => setTimeout(r, ms));


export const randomProperty = function (obj) {
    const keys = Object.keys(obj);
    return obj[keys[ keys.length * Math.random() << 0]];
};