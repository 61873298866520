import { nextAnswerAtom } from "@/common/recoil";
import { vh } from "@/common/styled-components";
import {
  isArchSelectedAtom,
  stepAtom,
  stepsValuesFamily,
  _devHints
} from "@/components/games/DivisioniGame/recoil";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

export default function Slot({
  index = null,
  row = null,
  col = null,
  ...props
}) {
  const nextAnswer = useRecoilValue(nextAnswerAtom);
  const value = useRecoilValue(stepsValuesFamily(index));
  const step = useRecoilValue(stepAtom);
  const isArchSelected = useRecoilValue(isArchSelectedAtom);
  const devHints = useRecoilValue(_devHints);

  const [classes, setClasses] = useState([]);

  useEffect(() => {
    if (!isArchSelected) return;

    // console.log("S", value.value, value.type, col, value.col, row, value.row -1)

    // if (value.type === "down" && col === value.col) {
    //   console.log("DDDDDOWQN")
    // }

    if (step === index) {
      setClasses(["current"]);
    } else {
      setClasses([]);
    }

    return () => setClasses([]);
  }, [step, isArchSelected]);

  if (!value) return <Cell {...props}></Cell>;

  return (
    <Cell {...props} className={classes.join(" ")}>
      {devHints && (
        <div className="hint">
          [{index}] {value.value}
        </div>
      )}
      {value.pending && nextAnswer}
      {value.right && value.value}
    </Cell>
  );
}

export const Cell = styled.div`
  --x: var(--division-cell-x);
  border: 1px solid var(--c-grey);
  width: var(--x);
  height: var(--x);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${vh(5)};
  font-family: var(--font-museo);
  font-weight: bold;
  color: var(--c-blue-d);

  &.current {
    transition-duration: 300ms;
    box-shadow: inset 0px 0px 0px ${vh(0.7, false)} var(--c-blue-l10);
  }

  .hint {
    font-size: 14px;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
