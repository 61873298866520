import { menuIsOpenAtom } from '@/common/recoil/index';
// @ts-ignore
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
// @ts-ignore
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilState } from 'recoil';
import styled from "styled-components";
import { vh } from "../../common/styled-components";


const Hamburger = () => {

    // const { menuIsOpen } = useGameContext();
    // const { menuIsOpenUpdate } = useGameContextUpdate();

    const [menuIsOpen, menuIsOpenSet] = useRecoilState(menuIsOpenAtom)

    return <Button onClick={() => menuIsOpenSet(o => !o)}>
        {!menuIsOpen && <FontAwesomeIcon icon={faBars} />}
        {menuIsOpen && <FontAwesomeIcon icon={faTimes} />}
    </Button>
}


export default Hamburger

const Button = styled.button`
    height: ${vh(10)};
    width: ${vh(10)};
    font-size: ${vh(3)};
    color: var(--c-blue);
    background: transparent;
    border: none;
    position: fixed;
    z-index: 55;
`