import { menuIsOpenAtom } from "@/common/recoil";
import MenuElement, { Elem } from "@/components/MenuContainer/MenuElement";
import config from "@/config";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { vh } from "../../common/styled-components";

const Menu = ({ fullpage = false }) => {
  const menuIsOpenSet = useSetRecoilState(menuIsOpenAtom)

  const [elements, elementsSet] = useState([]);

  useEffect(() => {
    const games = Object.keys(config.games);

    // const state = games.map((game) => {
    //   const gObj = config.games[game];
    //   return ({
    //     link: gObj.levels ? `/${game}/1/` : `/${game}/`,
    //     color: config.games[game].colors.main,
    //     title: config.games[game].title,
    //     icon: config.games[game].menuIcon,
    //     disabled: config.games[game].menuIsDisabled
    //   });
    // });



    elementsSet(Object.values(config.games));
  }, []);

  return (
    <>
      <Container
        onClick={() => {
          // menuIsOpenSet(false)
        }}
        className={`${fullpage ? "fullpage" : ""}`}
      >
        {elements &&
          elements.map((el, i) => (
            <MenuElement
              key={i}
              el={el}
            />
          ))}
        {fullpage && (
          <>
            <SubLink to="/istruzioni">Istruzioni</SubLink>
            <SubLink to="/crediti">Crediti</SubLink>
          </>
        )}
      </Container>
      <Backdrop></Backdrop>
    </>
  );
};

export default Menu;

// @ts-ignore
const SubLink = styled(Link)`
  font-size: 1.5rem;
  padding: 1rem 0;
  text-decoration: none;
  color: var(--c-blue-d) !important;
  text-align: center;

  &:nth-of-type(2n) {
    border-left: 2px solid var(--c-blue);
  }

`;

const Backdrop = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
`;

const Container = styled.div`
  position: fixed;
  top: 0mm;
  right: 0;
  /* width: 50vw; */
  height: ${vh(90)};
  background-color: var(--c-main);
  z-index: 50;
  padding-top: ${vh(10)};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  
  &.fullpage {
    width: 100%;
    height: 100%;
    padding-top: 0;

    ${Elem} {
      width: calc(50vw - 2 * var(--p));
    }
  }
`;

