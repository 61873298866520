import {
  nextAnswerAtom,
  playSoundRightAtom,
  playSoundWrongAtom
} from "@/common/recoil";
import useGlobalGameFunctions from "@/common/useGlobalGameFunctions";
import {
  antAnimationAtom,
  exeAtom,
  finalAnswerAtom,
  finalAnswerIndexAtom,
  inputAtom,
  nExeAtom,
  raceStateAtom
} from "@/components/games/ProvaGame/recoil";
import config from "@/config";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilCallback } from "recoil";

export default function useGameFunctions() {
  const { game, level } = useParams();
  const navigate = useNavigate();
  const { rightAnswerSound } = useGlobalGameFunctions()

  const _answerByIndex = (answer, index) => String(answer).split("")[index];

  const answerCheck = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        console.group("answerCheck");
        const finalAnswer = await snapshot.getPromise(finalAnswerAtom);
        const finalAnswerIndex = await snapshot.getPromise(
          finalAnswerIndexAtom
        );
        const ans = _answerByIndex(finalAnswer, finalAnswerIndex);
        const nextAnswer = await snapshot.getPromise(nextAnswerAtom);

        console.log("finalAnswer", finalAnswer);
        console.log("finalAnswerIndex", finalAnswerIndex);
        console.log("nextAnswer", nextAnswer);
        console.log("ans", ans);

        if (ans === String(nextAnswer)) {
          console.log("ok");
          // set(inputAtom, (i) => Number(String(i) + String(ans)))
          set(inputAtom, (i) => (i === null ? ans : i + ans));
          set(finalAnswerIndexAtom, finalAnswerIndex + 1);
          if (finalAnswerIndex === String(finalAnswer).split("").length - 1)
            return await rightAnswerSound()
          else return { ms: 0 }
        } else {
          set(playSoundWrongAtom, new Date());
          return { ms: 0 }
        }

        console.groupEnd();
      },
    []
  );

  const isExeEnded = useRecoilCallback(({ snapshot }) => async () => {
    const finalAnswer = await snapshot.getPromise(finalAnswerAtom);
    const input = await snapshot.getPromise(inputAtom);
    console.log(input, finalAnswer);
    return String(finalAnswer) === String(input);
  });

  const _exeClear = useRecoilCallback(({ set }) => async () => {
    set(antAnimationAtom, "idle");
    set(raceStateAtom, "restart");
    set(inputAtom, null);
    set(finalAnswerIndexAtom, 0);
    set(nextAnswerAtom, null);
  });

  const exeRestart = useRecoilCallback(({ set }) => async () => {
    console.group("exeRestart");
    set(inputAtom, null);
    set(raceStateAtom, "running");
    set(finalAnswerIndexAtom, 0);
    set(nextAnswerAtom, null);

    console.groupEnd();
  });

  const exeNext = useRecoilCallback(({ snapshot, set }) => async () => {
    console.group("exeNext");

    const exeIndex = await snapshot.getPromise(nExeAtom);
    const currentGameConfig = config.games.prova;
    const newExeIndex = exeIndex + 1;
    await _exeClear();

    // await clearExe();
    if (exeIndex < currentGameConfig.levels[level].exercises.length - 1) {
      /**
       * PROSSIMO ESERCIZIO
       */
      console.log("• NUOVO EXE");
      set(exeAtom, currentGameConfig.levels[level].exercises[newExeIndex]);
      set(nExeAtom, newExeIndex);
    } else {
      /**
       * PROSSIMO LIVELLO
       */
      console.log("• PROSSIMO LIVELLO •");
      const levels = Object.keys(currentGameConfig.levels);
      const nextLevel = levels[levels.indexOf(level) + 1];
      if (nextLevel) {
        navigate(`/${game}/${nextLevel}`);
      } else {
        navigate("/home");
      }
    }

    console.groupEnd();
  });

  return {
    answerCheck,
    isExeEnded,
    exeRestart,
    exeNext,
  };
}
