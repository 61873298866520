import { vh } from "@/common/styled-components";
import styled from "styled-components";

export default function Game({ children }) {
  return <GameStyled>{children}</GameStyled>;
}

const GameStyled = styled.div`
  --game-padding: ${vh(4)};
  --game-padding-x: ${vh(5)};
  padding: var(--game-padding) var(--game-padding-x);
  position: relative;
  display: flex;
  justify-content: space-between;
  
  @media (min-aspect-ratio: 16/9) {
    justify-content: space-around;
  }
`;
