// import { useGameContext } from "../../contexts/GameContext";
import { menuIsOpenAtom } from "@/common/recoil/index";
import { useRecoilValue } from "recoil";
import Menu from "./Menu";


const MenuContainer = () => {
    const menuIsOpen = useRecoilValue(menuIsOpenAtom)
    

    return <>
        {menuIsOpen && <>
            <Menu />
        </>}
    </>
}

export default MenuContainer;