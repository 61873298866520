import { nAnswerAtom, nextAnswerAtom } from "@/common/recoil";
import { vh } from "@/common/styled-components";
import { slotsValues } from "@/components/games/MoltiplicazioniGame/recoil";
import { useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";

export default function Slot({ n = null, className = null }) {
  const [nextAnswer, setNextAnswer] = useRecoilState(nextAnswerAtom);
  const [nAnswer, setNAnswer] = useRecoilState(nAnswerAtom);

  const [answer, setAnswer] = useState(null);
  const [current, setCurrent] = useState(false);

  // const [slotAnswer, setSlotAnswer] = useRecoilState(slotsState(n));
  const [slotAnswer, setSlotAnswer] = useRecoilState(slotsValues(n));

  //   useEffect(
  //     useRecoilCallback(({ set }) => {
  //       if (!n) return;
  //       console.log(n, nAnswer);
  //       if (nAnswer !== n) return;
  //       setCurrent(true);
  //       //   console.log("Slot: nextAnswer", nextAnswer);
  //       //   setAnswer(nextAnswer);

  //       //   setNextAnswer(null)
  //       //   setNAnswer((na) => na + 1);
  //     }),
  //     [nAnswer, nextAnswer]
  //   );

  // @ts-ignore
  return (
    <>
      <SlotElem className={className}>{slotAnswer}</SlotElem>
    </>
  );
}

export const SlotElem = styled.div`
  display: flex;
  width: var(--cell-x);
  height: var(--cell-x);
  margin: ${vh(0.2)};
  justify-content: center;
  align-items: center;
  font-size: var(--cell-x);
  color: #fff;
  font-family: var(--font-museo);
  
  &.answer-slot {
    /* border: 1px solid var(--c-blue-l); */
  }

  &.current {
    background-color: var(--c-blue-l);
  }
`;
