// @ts-ignore
import icon from "@/assets/img/header-icon.png";
import { gameTitleAtom, hamburgerHiddenAtom } from "@/common/recoil/index";
import { vh } from "@/common/styled-components";
import Hamburger from "@/components/Header/Hamburger";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

const Header = ({ backBtn }) => {
  const hamburgerHidden = useRecoilValue(hamburgerHiddenAtom);
  const gameTitle = useRecoilValue(gameTitleAtom);

  return (
    <Container>
      {backBtn && (
        <Back to="/home">
          <FontAwesomeIcon icon={faArrowLeft} />
        </Back>
      )}

      {!backBtn && <Icon src={icon} />}

      <Title>{gameTitle}</Title>
      {!hamburgerHidden && <Hamburger />}
    </Container>
  );
};

export default Header;

const Icon = styled.img`
  position: absolute;
  top: ${vh(1.5)};
  left: ${vh(1.5)};
  height: ${vh(7)};
  width: ${vh(7)};
`;

const Title = styled.div`
  height: ${vh(10)};
  width: calc(100% - (0.2 * var(--vh)));
  left: ${vh(10)};
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: var(--c-blue);
`;

const Back = styled(Link)`
  height: ${vh(10)};
  width: ${vh(10)};
  font-size: ${vh(3)};
  color: var(--c-blue);
  background: transparent;
  border: none;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  z-index: 500;
`;

const Container = styled.div`
  width: 100%;
  height: ${vh(10)};
  background-color: var(--c-main);
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
`;
