import { atom, atomFamily, selector } from "recoil";

export const exeAtom = atom({
  key: "divisioni_exe",
  default: null,
});

export const rowOneAtom = atom({
  key: "divisioni_row_one",
  default: null,
});

export const nExeAtom = atom({
  key: "divisioni_n_exe",
  default: 0,
});

export const stepsAtom = atom({
  key: "divisioni_steps",
  default: null,
})

export const stepAtom = atom({
  key: "divisioni_step_current",
  default: 0
})

export const currentValueState = selector({
  key: "divisioni_current_value",
  get: ({get}) => {
    const steps = get(stepsAtom)
    const step = get(stepAtom)
    return steps[step]
  }
})

export const stepsValuesFamily = atomFamily({
  key: "divisioni_steps_valies_fam",
  default: null,
})

export const stepsValuesLenAtom = atom({
  key: "divisioni_steps_valies_fam_len",
  default: [],
})

export const isArchSelectedAtom = atom({
  key: "divisioni_is_arch_selected_step",
  default: false,
})

export const isVerifyingAtom = atom({
  key: "divisioni_verifying",
  default: false,
});

export const _devHints = atom({
  key: "_divisioni_hints",
  default: false
})