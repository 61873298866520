import config from "@/config";
import { useParams } from "react-router-dom";
import { atom, selector } from "recoil";

export const currentOperatorAtom = atom({
  key: "currentOperator",
  default: 2,
});

export const hintShowAtom = atom({
  key: "hintShow",
  default: false,
});

export const resultShowAtom = atom({
  key: "resultShow",
  default: false,
});

export const gameTitleAtom = atom({
  key: "gameTitle",
  default: "",
});

export const currentOperatorConfigState = selector({
  key: "currentOperatorConfig",
  get: ({ get }) => {
    const currentOperator = get(currentOperatorAtom);
    if (!currentOperator) {
      return {};
    }
    return config.operators[currentOperator];
  },
});

export const gameConfigAtom = atom({
  key: "currentGame",
  default: null,
})

export const currentGameConfigState = selector({
  key: "currentGameConfig",
  get: ({ get }) => {
    return {}
    const { game } = useParams();
    return config.games[game];
  },
});

export const nextAnswerAtom = atom({
  key: "nextAnswer",
  default: null,
});

export const nAnswerAtom = atom({
  key: "nAnswer",
  default: 0,
});


export const lastWasLastAtom = atom({
  key: "lastWasLast",
  default: false,
});

export const answerCountAtom = atom({
  key: "answerCount",
  default: 0,
});


export const calculatorRightPositionAtom = atom({
  key: "calculatorRightPosition",
  default: 0,
});