import arch1 from "@/assets/img/arches/arch1.svg";
import arch2 from "@/assets/img/arches/arch2.svg";
import { vh } from "@/common/styled-components";
import { exeAtom } from "@/components/games/DivisioniGame/recoil";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

export default function ArchesFirstStep() {
    const [,,arch] = useRecoilValue(exeAtom);

    return <Elem data-arch={arch}>
        {arch === 1 && <><img src={arch1} /></>}
        {arch === 2 && <><img src={arch2} /></>}
        {arch === 3 && <>Arch 3</>}
    </Elem>
}

const Elem = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(calc(var(--division-cell-x) - 100%));
    width: calc(${p => p["data-arch"]} * var(--division-cell-x) + ${p => p["data-arch"] - 1} * 4px);
    /* border: 1px solid; */
    z-index: 100;
    text-align: center;

    img {
        height: ${vh(1)}
    }
`