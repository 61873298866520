import {
  currentOperatorConfigState
} from "@/common/recoil";
import { vh } from "@/common/styled-components";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

export default function Ant() {
  const { antIcon } = useRecoilValue(currentOperatorConfigState);

  return <Elem>{antIcon && <img src={antIcon} alt="ant" />}</Elem>;
}

const Elem = styled.div`
  position: fixed;
  bottom: ${vh(10)};
  right: 5%;
  width: 15%;
  height: ${vh(30)};
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
`;
