import erickson from "@/assets/img/erickson.svg";
import logos from "@/assets/img/loghi.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import splashImg from "../assets/img/splash.png";
import { vh } from "../common/styled-components";

const Splash = () => {
  // const history = useHistory()
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/home");
    }, 5000);
  }, []);

  return (
    <Container>
      <img src={splashImg} alt="icons" className="icons" />
        <h2>Camillo Bortolato</h2>
        <h1><b>TABELLINE</b><br />DIGITALI</h1>
        <div className="logos">
            {/* <img className="ma" src={ma} alt="" />
            <img className="ms-3" src={erickson} alt="" /> */}
            <img src={logos} alt="" />
        </div>

    </Container>
  );
};

export default Splash;

const Container = styled.div`
  /* background-color: #3A8696;
    .splash {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: auto;
        }
    } */

  background-color: var(--cMain);

  img.icons {
    position: fixed;
    top: ${vh(0)};
    right: ${vh(10)};
    height: ${vh(70)};
  }

  h1 {
    font-family: var(--font-gill) !important;
    width: fit-content;
    line-height: 1;
    font-weight: 200;
    font-size: ${vh(8)};
    color: #fff;
    position: absolute;
    top: ${vh(40)};
    left: ${vh(10)};

    b {
      font-weight: 600;
    }
  }

  h2 {
    font-family: var(--font-gill) !important;
    font-weight: 400;
    color: #fff;
    width: fit-content;
    font-size: ${vh(4)};
    position: absolute;
    top: ${vh(22)};
    left: ${vh(10)};
  }

  .logos {
    position: absolute;
    bottom: ${vh(5)};
    right: ${vh(10)};
    display: flex;
    align-items: baseline;
    gap: ${vh(2)};

    img {
      height: ${vh(10)};
    }
  }
`;
