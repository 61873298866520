import antHit from "@/assets/img/ants/hit.gif";
import antIdle from "@/assets/img/ants/idle.png";
import { antAnimationAtom, antLoadedAtom } from "@/components/games/ProvaGame/recoil";
import Toolbar from "@/components/Toolbar";
import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";

export default function AntAnimated() {
  const [antAnimation] = useRecoilState(antAnimationAtom);
  const setAntLoaded = useSetRecoilState(antLoadedAtom)
  const [image, setImage] = useState();

  useEffect(() => {
    setImage(antAnimation === "idle" ? antIdle : antHit);
  }, [antAnimation]);

  return (
    <Elem>
      <Toolbar showResult={false} />
      <img src={image} className="ant" alt="ant" onLoad={() => setAntLoaded(true)} />
    </Elem>
  );
}

const Elem = styled.div`
  /* width: 100px; */
  width: fit-content;
  height: 100%;
  /* border: 1px solid; */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: var(--left);

  .ant {
    width: auto;
    height: 100%;
    object-fit: contain;
    margin-left: 2vw;
  }

  .toolbar {
    position: relative;
    margin-top: 0;
    bottom: 0;

    @media (min-aspect-ratio: 16/9) {
      left: unset;
    }
  }
`;
