export const t = {
  digitalKeyboard: "TABELLINE",
  iReadWithPitti: "Leggo con Pitti",
  kerning: "Kerning",
  spaceSize: "Dimensione spazio",
  alignment: "Allineamento",
  left: "Sinistra",
  center: "Centro",
  configurationsAlertTitle:
    "Attenzione: Parametri di configurazione delle tabelline digitali.",
  warningSmallScreen:
    "Attenzione, non è possibile utilizzare le tabelline digitali con questa risoluzione. Utilizza un dispositivo con uno schermo più grande.",
};
