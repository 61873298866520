import { sleep } from "@/common/functions";
import {
  lastWasLastAtom,
  nextAnswerAtom,
  playSoundRightAtom,
  playSoundWrongAtom
} from "@/common/recoil";
import useGlobalGameFunctions from "@/common/useGlobalGameFunctions";
import {
  answerAtom,
  answerMapAtom,
  currentSlotNAtom,
  currentSlotPropsSelector,
  exeAtom,
  exeIndexAtom,
  isVerifyingAtom,
  slotsValues
} from "@/components/games/MoltiplicazioniGame/recoil";
import config from "@/config";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilCallback } from "recoil";

export default function useGameFunctions() {
  const { level, game } = useParams();
  const navigate = useNavigate();
  const { rightAnswerSound } = useGlobalGameFunctions()

  const _checkNextAnswer = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const currentN = await snapshot.getPromise(currentSlotNAtom);
        const answer = await snapshot.getPromise(answerAtom);
        const nextAnswer = await snapshot.getPromise(nextAnswerAtom);
        return String(nextAnswer) === String(answer[currentN]);
      },
    []
  );

  const _undoLastAnswer = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const currentN = await snapshot.getPromise(currentSlotNAtom);
        const answer = await snapshot.getPromise(answerAtom);
        const nextAnswer = await snapshot.getPromise(nextAnswerAtom);
        set(slotsValues(currentN), null);
        // set(currentSlotNAtom, currentN - 1);
      },
    []
  );

  const _nGoNext = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const cn = await snapshot.getPromise(currentSlotNAtom);
        const lastWasLast = await snapshot.getPromise(lastWasLastAtom);
        const currentSlotProps = await snapshot.getPromise(
          currentSlotPropsSelector
        );
        const { rowHasLastInversion, is2fromLast, isLast, isLastLast } =
          currentSlotProps;

        if (rowHasLastInversion) {
          console.log("ROW HAS LAST INVERSION");
          if (lastWasLast) {
            console.log("LAST WAS LAST");
            set(currentSlotNAtom, cn + 2);
            set(lastWasLastAtom, false);
          } else if (is2fromLast) {
            console.log("IS 2 FROM LAST");
            set(currentSlotNAtom, cn + 2);
          } else if (isLast) {
            console.log("IS LAST");
            set(currentSlotNAtom, cn - 1);
            set(lastWasLastAtom, true);
          } else {
            console.log("ELSE");
            set(currentSlotNAtom, cn + 1);
          }
        } else {
          console.log("ROW HAS NOT LAST INVERSION");
          set(currentSlotNAtom, cn + 1);
        }

        console.log("••• BEFORE  NEXT: LAST WAS LAST: ", isLastLast);

        // if (isLastLast) {
        //   alert("IS LAST LAST");
        // }
        // if (lastWasLast) {
        //   console.log("LAST WAS LAST");
        //   set(currentSlotNAtom, cn + 2);
        //   set(lastWasLastAtom, false);
        // } else if (is2fromLast) {
        //   console.log("IS 2 FROM LAST");
        //   set(currentSlotNAtom, cn + 2);
        // } else if (isLast) {
        //   console.log("IS LAST");
        //   set(currentSlotNAtom, cn - 1);
        //   set(lastWasLastAtom, true);
        // } else {
        //   console.log("ELSE");
        //   set(currentSlotNAtom, cn + 1);
        // }
        console.log("currentSlotProps", currentSlotProps);
      },
    []
  );

  const insertNextAnswer = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ }) => {
        const cn = await snapshot.getPromise(currentSlotNAtom);
        const nextAnswer = await snapshot.getPromise(nextAnswerAtom);
        set(slotsValues(cn), nextAnswer);

        // check if right
        const isRight = await _checkNextAnswer();
        console.log("isRight", isRight);
        await sleep(300);

        if (isRight) {
          // set(playSoundRightAtom, new Date())
          await _nGoNext();
        } else {
          set(playSoundWrongAtom, new Date())
          await _undoLastAnswer();
        }

        set(nextAnswerAtom, null);
        set(isVerifyingAtom, false);
      },
    []
  );

  const isExeEnded = useRecoilCallback(({ snapshot, set }) => async () => {
    const current = await snapshot.getPromise(currentSlotNAtom);
    const answer = await snapshot.getPromise(answerAtom);
    console.log("IS ENDED?", current, answer.length);
    return current === answer.length;
  });

  const _clearExe = useRecoilCallback(({ snapshot, set }) => async () => {
    const current = await snapshot.getPromise(currentSlotNAtom);
    const answer = await snapshot.getPromise(answerAtom);
    console.log("CLEAR EXE", current, answer.length);

    for (let i = 0; i < answer.length; i++) {
      set(slotsValues(i), null);
    }

    set(currentSlotNAtom, 0);
    set(lastWasLastAtom, false);
    set(exeIndexAtom, 0);
    // set(exeAtom, []);
    set(nextAnswerAtom, null);
    set(isVerifyingAtom, false);
    set(answerAtom, null);
    set(answerMapAtom, null);
  });

  const nextExercise = useRecoilCallback(({ snapshot, set }) => async () => {
    const exeIndex = await snapshot.getPromise(exeIndexAtom);
    const currentGameConfig = config.games.moltiplicazioni;
    const newExeIndex = exeIndex + 1;

    const { ms } = await rightAnswerSound()

    await sleep(ms);

    await _clearExe();
    if (exeIndex < currentGameConfig.levels[level].exercises.length - 1) {
      /**
       * PROSSIMO ESERCIZIO
       */
      console.log("• NUOVO EXE");
      set(exeAtom, currentGameConfig.levels[level].exercises[newExeIndex]);
      set(exeIndexAtom, newExeIndex);
    } else {
      /**
       * PROSSIMO LIVELLO
       */
      console.log("• PROSSIMO LIVELLO •");
      const levels = Object.keys(currentGameConfig.levels);
      const nextLevel = levels[levels.indexOf(level) + 1];
      if (nextLevel) {
        navigate(`/${game}/${nextLevel}`);
      } else {
        navigate("/home");
      }
    }
  });

  return {
    insertNextAnswer,
    isExeEnded,
    nextExercise,
  };
}
