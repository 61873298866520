import { atom } from "recoil";

export const inputAtom = atom({
  key: "prova_inputAtom",
  default: null,
});

export const exeAtom = atom({
  key: "prova_exe",
  default: null,
});

export const nExeAtom = atom({
  key: "prova_nExe",
  default: null,
});

export const raceStartAtom = atom({
  key: "prova_raceStart",
  default: false,
});

export const raceStateAtom = atom({
  key: "prova_raceState",
  default: "paused",
  // ["paused", "running", "lose", "win"]
});

export const antAnimationAtom = atom({
  key: "prova_antAnimation",
  default: "idle",
});

export const finalAnswerAtom = atom({
  key: "prova_finalAnswer",
  default: null,
});

export const finalAnswerIndexAtom = atom({
  key: "prova_finalAnswerIndex",
  default: 0,
});

export const birdCtrlAtom = atom({
  key: "prova_birdCtrl",
  default: null,
});

export const raceLeftPositionAtom = atom({
  key: "prova_raceLeftPosition",
  default: 0,
});

export const antLoadedAtom = atom({
  key: "prova_antLoaded",
  default: false,
});