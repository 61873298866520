import { randomProperty, sleep } from "@/common/functions";
import {
  nextAnswerAtom,
  playSoundGameOverAtom,
  playSoundWrongAtom
} from "@/common/recoil";
import { vh } from "@/common/styled-components";
import AntAnimated from "@/components/games/ProvaGame/AntAnimated";
import ExeCounter from "@/components/games/ProvaGame/ExeCounter";
import useGameFunctions from "@/components/games/ProvaGame/hooks/useGameFunctions";
import {
  antAnimationAtom,
  exeAtom,
  finalAnswerAtom,
  finalAnswerIndexAtom,
  inputAtom,
  nExeAtom,
  raceLeftPositionAtom,
  raceStateAtom
} from "@/components/games/ProvaGame/recoil";
import Bird from "@/components/Race/Bird";
import Toolbar from "@/components/Toolbar";
import config, { animals } from "@/config";
import useSounds from "@/hooks/useSounds";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
function shuffle(sourceArray) {
  for (var i = 0; i < sourceArray.length - 1; i++) {
    var j = i + Math.floor(Math.random() * (sourceArray.length - i));

    var temp = sourceArray[j];
    sourceArray[j] = sourceArray[i];
    sourceArray[i] = temp;
  }
  return sourceArray;
}

export default function Race({}) {
  const { level } = useParams();
  const birdContRef = useRef();
  const { soundGameOver } = useSounds();

  const { answerCheck, exeRestart, exeNext } = useGameFunctions();

  const [nExe, setNExe] = useRecoilState(nExeAtom);
  const [exe, setExe] = useRecoilState(exeAtom);
  const [antAnimation, setAntAnimation] = useRecoilState(antAnimationAtom);

  const [raceState, setRaceState] = useRecoilState(raceStateAtom);
  const input = useRecoilValue(inputAtom);
  const nextAnswer = useRecoilValue(nextAnswerAtom);
  const raceLeftPosition = useRecoilValue(raceLeftPositionAtom);
  const setFinalAnswer = useSetRecoilState(finalAnswerAtom);
  const setFinalAnswerIndex = useSetRecoilState(finalAnswerIndexAtom);
  const setPlaySoundWrong = useSetRecoilState(playSoundWrongAtom);
  const setPlaySoundGameOver = useSetRecoilState(playSoundGameOverAtom);

  const [exercises, setExercises] = useState([]);

  useEffect(() => {
    if (!input) return;
  }, [input]);

  // ON LEVEL CHANGE
  useEffect(() => {
    if (!level) return;
    setNExe(0);
    // const shuffled = config.games.prova.levels[level].exercises.sort(
    //   (a, b) => 0.5 - Math.random()
    // );
    setExercises(_.shuffle(config.games.prova.levels[level].exercises));
  }, [level]);

  useEffect(() => {
    if (!exercises.length) return;
    setExe(exercises[nExe]);
  }, [exercises, nExe]);

  // ON EXERCISE CHANGE
  useEffect(() => {
    if (!exe) return;
    console.log("exe", exe);
    setFinalAnswer(Number(exe[0]) * Number(exe[1]));
    setFinalAnswerIndex(0);
    setRaceState("running");
  }, [exe]);

  // ON RACE STATE CHANGE
  useEffect(() => {
    if (!raceState) return;
    console.log("[RACE] raceState", raceState);

    switch (raceState) {
      case "running":
        setAntAnimation("idle");
        break;

      case "lose":
        (async () => {
          setPlaySoundGameOver(new Date());
          await sleep(2500);
          exeRestart();
        })();
        break;

      case "win":
        (async () => {
          // await sleep(currentGameConfig.delay);
          exeNext();
        })();
        break;
    }
  }, [raceState]);

  // ON ANT DIE
  useEffect(() => {
    (async () => {
      switch (antAnimation) {
        case "die":
          // setPlaySoundWrong(new Date());
          console.log("YOU LOSE");
          setRaceState("lose");
          break;
      }
    })();
  }, [antAnimation]);

  useEffect(()=> {
    console.log("birdContRef", birdContRef.current.clientWidth);
  }, [birdContRef?.current?.clientWidth])

  return (
    <>
      <Elem data-left={raceLeftPosition > 0 && raceLeftPosition}>
        <AntAnimated />
        <BirdContainer ref={birdContRef}>
          <Bird contRef={birdContRef} />
        </BirdContainer>
        <ExeCounter />
      </Elem>
    </>
  );
}

const Elem = styled.div`
  --left: ${(props) => props["data-left"]}px;
  position: absolute;
  z-index: 2;
  left: 0;
  height: ${vh(13)};
  bottom: ${vh(-12)};
  width: calc(100%);
  margin-top: ${vh(4)};
  /* border: 1px solid; */
  display: flex;
`;

const BirdContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  position: relative;
`;
