export const URL_HOME_MAB = 'https://risorseonline.erickson.it/mab/';
export const URL_ISLOGGED_API = `${URL_HOME_MAB}app/user_islogged.php`;



export const ROUTES = {
    'home': '/',
    'crediti': '/crediti',
    'istruzioni': '/istruzioni',
    'uso_libero': '/uso_libero',
    'leggi_con_pitti': '/leggi_con_pitti'
}